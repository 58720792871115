.srv-validation-message{
    color:red !important;
}

.app-main-header{
    overflow:hidden;
}
.MuiTableRow-hover:hover{
    background-color: #37dfb6 !important;
}

.fileContainer p{
    display:none;
}
.fileContainer .uploadIcon{
    display:none;
}

.fileContainer .chooseFileButton {
    cursor: pointer!important;
    font-size: 13px!important;
    font-weight: normal!important;
    font-stretch: normal!important;
    font-style: normal!important;
    outline: none!important;
    border: none!important;
    width: 213px!important;
    height: 41px!important;
    box-shadow: 0 2px 0 0 rgba(107, 63, 223, 0.25)!important;
    background-image: linear-gradient(to top, #6b3fdf, #8f65ff)!important;
    letter-spacing: normal!important;
    color: #ffffff!important;
    font-family: 'RobotoRegular'!important;
    display: table!important;
    border-radius: 0px!important;
}

.fileContainer .uploadPictureContainer {
    /*width: 25%!important;*/
    margin: 5%!important;
    padding: 0!important;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: none!important;
    border: 1px solid #d0dbe4;
    position: relative;
}
