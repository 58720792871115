.checkbox-button__input {
    opacity: 0;
}

.checkbox-button__control {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 12px;
    vertical-align: middle;
    background-color: inherit;
    border: solid 1px #dedcec;
    cursor: pointer;
    transform: scale(0.75);
    margin-top: -3px;
}

.checkbox-button__input:checked+.checkbox-button__control:after {
    content: "";
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    background-color: $secondary-3;
    border-radius: 2px;
}

.club-checkbox__wrapper .checkbox-button__label {
    cursor: pointer;
}