.orders-wrapper {
  display: flex;
  height: 100%;
}

.orders-wrapper > div:nth-child(1) {
  //width: 201px !important;
  min-width: 201px;
}

.orders-container {
  width: 100%;
  overflow-x: auto;
}

.orders-card {
  padding-left: 20px;
  padding-right: 25px;
}

.table-responsive-material td {
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th {
  padding-left: 35px;
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th:nth-child(1) {
  padding-left: 0px;
}

.orders-wizard {
  height: 100%;
  display: flex;
}

.attributes-wrapper {
  width: 100%;
}
.orders-general {
  padding: 0 10px;
}

.orders-general h1 {
  font-family: 'RobotoRegular';
  padding-left: 25px;
  display: flex;
  height: 60px;
  margin-bottom: 0px;
  align-items: center;
  font-size: 13px;
  line-height: 42px;
  letter-spacing: 0;
  border-bottom: 1px solid rgb(222, 220, 236);
  color: rgb(39, 37, 40);
}
.orders-general form {
  padding: 20px 30px;
  font-size: 13px;
  font-family: 'RobotoRegular';
  line-height: 50px;
  letter-spacing: 0;
  color: rgb(39, 37, 40);
}
.orders-general form input {
  width: 475px;
  height: 41px;
  padding-left: 10px;
}

.orders-general form span {
  width: 100px;
  display: inline-block;
}

@media (max-width: 950px) {
  .orders-card {
    padding-left: 0px;
    padding-right: 0px;
    overflow: auto;
  }
}

@media (max-width: 414px) {
  //.orders-wrapper {
  //  display: flex;
  //  flex-direction: column;
  //}
  .orders-resp {
    width: 100% !important;
    height: auto !important;
  }
}
