.club-wrapper {
  width: 100%;
  height: 100%;
}

.club-main-wrapper {
  width: calc(100% - 200px);
}
.app-main-header > div {
  width: 100%;
}
.club-products-container {
  padding: 30px;
  margin: 0px;
  // display: flex;
  // flex-wrap:wrap;
}
.product-box {
  position: relative;
  .product-select-button {
    position: absolute;
    top: 10px;
    left: 20px;
    display: block;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 100;

    /* Hide the browser's default radio button */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom radio button */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: transparent;
      border: 1px solid #eee;
      border-radius: 50%;
      cursor: pointer;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
      cursor: pointer;
    }

    /* When the radio button is checked, add a blue background */
    input:checked ~ .checkmark {
      background-color: #54c08a;
    }

    /* Create the indicator (the dot/circle - hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the indicator (dot/circle) when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the indicator (dot/circle) */
    .checkmark:after {
      top: 3px;
      left: 6px;
      width: 6px;
      height: 10px;
      border: 2px solid #fff;
      border-top: none;
      border-left: 0;
      transform: rotate(45deg);
    }
  }
}

.club-product {
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 0 -1px rgba(205, 205, 205, 0.5);
  border: solid 1px rgba(183, 185, 203, 0.37);
  background-color: #ffffff;
}

.club-product:nth-child(6n) {
  margin-right: 0;
}

.product-description {
  margin-top: 15px;
}
.product-domain {
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #006fff;
}

.product-name {
  height: 30px;
  overflow: hidden;
  margin-top: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b4850;
}

.product-price {
  margin-top: 15px;
  position: relative;
}

.product-old-price {
  margin-right: 10px;
  width: 33px;
  height: 20px;
  text-decoration: line-through;
  font-family: 'gilroyMedium';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #929292;
}

.product-filters {
  padding-top: 20px;
}
.product-filters h1 {
  margin-bottom: 7px;
  padding-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #312e34;
}

.product-new-price {
  width: 35px;
  height: 20px;
  font-family: 'gilroyMedium';
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #494458;
}
.product-discount {
  position: absolute;
  right: 0px;
  bottom: 1px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 26px;
  border-radius: 4px;
  //background-image: linear-gradient(58deg, #6b3fdf 0%, #8f65ff 101%);
  background-color: #fafafc;
  border: 1px dashed #807f93;
  font-family: 'gilroyMedium';
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.63;
  letter-spacing: normal;
  color: #494458;
}

.product-image {
  display: flex;
  padding-top: 15px;
  justify-content: center;
}

.product-image img {
  width: 110px;
  height: 165px;
  object-fit: contain;
}

.manage-catalog {
  display: flex;
  padding-left: 15px;
  align-items: center;
  height: 55px;
  border-bottom: solid 1px #dfddee;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #312e34;
}

.add-new-product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 61px;
  border-bottom: solid 1px #dfddee;
}
.filters-container {
  display: flex;
  flex-direction: column;
}

.filters-container select {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b4850;
}
.add-new-product-button {
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  border: none;
  outline: none !important;
  width: 180px;
  outline: none;
  height: 40px;
  border-radius: 4px;
  background-color: #37dfb6;
}

.add-new-product-button--image {
  margin-left: 15px;
}

.category-heading {
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  border: solid 1px #dfddee;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8;
  letter-spacing: normal;
  color: #272528;
}

.single-category-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8;
  letter-spacing: normal;
  color: #272528;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #ececf4;
}

.category-listing {
  overflow: auto;
  height: 800px;
  border-top: solid 1px #dedcec;
  background-color: #ffffff;
}
//.MuiInput-underline::before{
//    content:"" !important;
//    left: 300px !important;
//}

.product-wizard-wrapper {
  height: 100%;
}

.tab-item {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  opacity: 1;
  color: #000000;
  font-size: 13px;
  font-family: 'RobotoRegular';
}

.tab-item:hover,
.tab-item--active,
.tab-item.active {
  font-family: 'RobotoMedium';
  background: rgba(118, 53, 243, 0.06);
  border-right: 2px solid #009ee3;
}

.tab-item:hover span,
.tab-item--active span {
  z-index: 100;
  opacity: 1;
  color: #009ee3 !important;
}

.tab-item:hover,
.tab-item--error,
.tab-item.error {
  font-family: 'RobotoMedium';
  background: rgba(118, 53, 243, 0.06);
  border-right: 2px solid red;
}

.tab-item:hover span,
.tab-item--error span {
  z-index: 100;
  opacity: 1;
  color: red !important;
}

.tab-header {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8;
  letter-spacing: normal;
  color: #272528;
  border-bottom: 1px solid #dfddee;
}

.tab-wrapper {
  position: relative;
  width: 201px;
  min-width: 201px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dfddee;
}
.tab-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 15px;
}
.tab-buttons button,
.club-import-products__button {
  border: none;
  background: none;
  outline: none;
  width: 180px;
  height: 40px;
  border-radius: 4px;
  background-color: #6eb24b;
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.tab-buttons a button {
  margin-top: 5px;
  background-color: #c8c8ce;
}

.create-new-attribute {
  .jr-card-g {
    background: none !important;
    margin-top: 25px;
    .switch_label {
      min-width: 150px !important;
      display: flex !important;
      margin-right: 25px !important;
      align-items: center !important;
      justify-content: end !important;
      flex-direction: row !important;
    }
  }
}

.atrribute-buttons {
  position: absolute;
  right: 20px;
}
.atrribute-buttons button {
  border: none;
  background: none;
  outline: none;
  width: 180px;
  height: 40px;
  border-radius: 4px;
  background-color: #6eb24b;
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

.attribute-cancel-button {
  background-color: #c8c8ce !important;
  margin-right: 10px;
}

.single-category-input {
  position: relative;
  height: 50px;
  border-radius: 4px;
}

.single-category-input i {
  position: absolute;
  right: 9px;
  top: 17px;
  cursor: pointer;
  z-index: 1;
}

.single-category-input input {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  opacity: 0.38;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;
  color: #545475;
}
.single-category {
  margin-right: 20px;
  width: 222px;
  border: 1px solid #dedcec;
  border-radius: 4px;
}
.category-name {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #272528;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.category-name:hover {
  cursor: pointer;
  background-color: #009ee3;
  color: #ffffff;
}

.category-listing-wrapper {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
}

.product-wizard-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.product-attributes {
  width: 100%;
  .switch_label {
    min-width: 150px !important;
    flex-direction: row !important;
    justify-content: end !important;
    margin-right: 25px !important;
  }
}

.app-container.mini-drawer .app-main-header {
  width: 100% !important;
}

.club-input__wrapper,
.club-select__wrapper,
.club-switch__wrapper,
.club-datepicker__wrapper {
  display: flex;
  align-items: center;
}

.club-datepicker__wrapper {
  width: 313px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.club-datepicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 201px;
  height: 41px;
  border: solid 1px #dedcec;
  background-color: #ffffff;
  padding: 11px;
}

.club-checkbox__wrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-right: 43px;
}

.club-input__wrapper input {
  padding: 11px;
  font-size: 13px;
  width: 475px;
}

.club-input__main,
.club-select__main,
.date-picker {
  margin: 10px 0 !important;
  fieldset {
    border-radius: 0;
    border: none;
    // border-color: $line-color-1 !important;
  }
  // &:not(:focus) {
  //     fieldset {
  //         // border-radius: 0;
  //         border-color: $line-color-1 !important;
  //     }
  // }
}

.club-input__wrapper span,
.club-select__wrapper span,
.club-switch__wrapper .club-switch__name,
.club-checkbox__wrapper .club-checkbox__name,
.club-datepicker__wrapper span {
  width: 120px;
  font-size: 13px;
  color: #272528;
}

.club-switch__wrapper .club-switch__name {
  text-transform: capitalize;
}

#select-merchant,
#select-brand,
#select-family {
  padding: 11px;
  width: 475px;
  font-size: 13px;
}

#menu-family ul li {
  font-family: 13px !important;
}

.product-countries__wrapper {
  display: flex;
}

.date-picker input {
  font-family: Roboto;
  font-size: 13px;
  color: #272528;
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  border: none !important;
}

.date-picker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
}

.calendar-icon {
  margin-left: -25px;
}

.paylix-club-countries__wrapper {
  height: 115px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.club-add-product-content {
  padding: 30px;

  .jr-card-g {
    background: #ffffff;
    border: solid 1px #dedcec;
    margin-bottom: 20px;
  }

  .jr-card-body {
    padding: 25px;
  }
}

.discount_container {
  display: flex;
}

.discount_percent {
  padding-top: 10px;
  padding-left: 5px;
}

.input-error {
  color: rgb(255, 0, 79);
  margin-left: 115px;
}

//Upload Images
.image-upload-content {
  .uploaded-images-container {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;

    .uploaded-image-box {
      width: 336px;
      height: 411px;
      border-radius: 4px;
      border: solid 1px #dedcec;
      background-color: #ffffff;
      margin-bottom: 20px;
      margin-right: 20px;
      position: relative;
      cursor: pointer;

      &:hover .uploaded-image-hoverbox {
        opacity: 1;
      }
      .uploaded-image {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 4px;
      }
      .uploaded-image-hoverbox {
        width: 100%;
        height: 50px;
        border-top: solid 1px #dedcec;
        background-color: #f7f6fa;
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        opacity: 0;
        transition: 0.5s ease-in-out;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        .hoverbox-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
          //border-left: solid 1px #dedcec;
          //border-right: solid 1px #dedcec;
        }
        .hoverbox-move {
          width: 40%;
        }
        .hoverbox-youtube {
          border: none;
        }
        .hoverbox-crop {
          border-bottom-left-radius: 4px;
          border-left: none;
        }
        .hoverbox-delete {
          border-bottom-right-radius: 4px;
          border: none;
        }
      }
    }
  }
}
.image-upload_button {
  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  color: #ffffff;
  border: none;
  outline: none !important;
  background-color: #37dfb6;
  width: 213px;
  height: 41px;
  box-shadow: 0 2px 0 0 rgba(107, 63, 223, 0.25);
  background-image: linear-gradient(0deg, #009ee3, #006fff);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 5px;
  }
}

// Options
.options-uploaded-imagebox {
  width: 60px;
  height: 60px;
  margin: auto;
  position: relative;
  margin-top: 13px;
  .options-remove-thumbnail {
    position: absolute;
    top: -15px;
    right: -15px;
  }
  .options-uploaded-thumbnail {
    width: 60px;
    height: 60px;
  }
}

.fileContainer {
  flex-wrap: wrap !important;
  div {
    flex-wrap: wrap !important;

    justify-content: flex-start !important;
    align-items: flex-start !important;
    justify-items: flex-start !important;
    align-content: flex-start !important;
  }
  .uploadPictureContainer {
    width: 300px !important;
    height: 300px !important;
    margin: 15px !important;
    padding: 0 !important;
    border: none !important;

    img.uploadPicture {
      height: 100% !important;
      object-fit: cover;
    }
  }
  .deleteImage {
    background-color: #fff !important;
    font-size: 18px !important;
    line-height: 27px !important;
    width: 27px !important;
    height: 27px !important;
    top: 0 !important;
    right: 0 !important;
    border-radius: 0 !important;
    color: #dc3545 !important;
  }
}

.jr-card-form {
  display: flex;
  flex-direction: column;
}

.jr-card-label {
  width: 600px;
  font-family: Roboto;
  font-size: 13px;
  color: #272528;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jr-card-input {
  width: 425px !important;
  height: 41px;
  padding: 0 10px;
  border-radius: 0px;
  border: solid 1px #dedcec;
  background-color: #ffffff;
  outline: none;
  font-family: Roboto;
  font-size: 13px;
  color: #272528;
  //margin-left: 28px;

  &:disabled {
    opacity: 1 !important;
  }
}
.jr-card-div {
  width: 425px !important;
  border: solid 1px #dedcec;
  padding: 10px;
  background-color: #fff;

  input[type='radio'] {
    margin: 5px;
  }
}

.jr-card-input--area {
  height: 300px;
  margin: 0;
  padding: 10px;
  resize: none;
}

.jr-card-select {
  background-color: #fbfbfd;
}

.jr-card-label--small {
  width: 328px;
}

.jr-card-input--small,
.jr-card-select--small {
  width: 213px;
}
.club-add-product-content--row {
  display: flex;
  flex-wrap: wrap;
}
.club-add-product-options__wrapper {
  flex-basis: 420px !important;
  display: flex;
  align-items: center;
}

.club-add-product-options__wrapper--small {
  flex-basis: 50px !important;
  .jr-card-g {
    background: none;
    border: none;
  }
}

.club-add-product-options__content {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 30px;
}

.club-add-product-options__add {
  color: #006eff7a;
  width: 50px !important;
  height: 50px !important;
  position: absolute;
  top: 0;
  left: 0;
}

.club-add-product-options__add:hover {
  color: #006fff;
}

.club-add-product-options__delete {
  cursor: pointer;
}
.club-add-product-options__delete:hover {
  color: #656464;
}

@media (max-width: 1500px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 19.99677%;
    max-width: 30%;
  }
}

@media (max-width: 1350px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 24.99677%;
    max-width: 30%;
  }
}

@media (max-width: 1100px) {
  .headline-text {
    display: none;
  }
  .club-products-container .col-lg-2 {
    flex: 0 0 37.99677%;
    max-width: 33.3%;
  }
}

@media (max-width: 900px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 49.99677%;
    max-width: 53.3%;
  }
}

@media (max-width: 660px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 104.99677%;
    max-width: 100.3%;
  }
}

.club-wrapper {
  width: 100%;
  height: 100%;
}

.club-main-wrapper {
  width: calc(100% - 200px);
}
.app-main-header > div {
  width: 100%;
}
.club-products-container {
  padding: 30px;
  margin: 0px;
  // display: flex;
  // flex-wrap:wrap;
}

.club-product {
  margin-bottom: 20px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  height: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 0 -1px rgba(205, 205, 205, 0.5);
  border: solid 1px rgba(183, 185, 203, 0.37);
  background-color: #ffffff;
}

.club-product:nth-child(6n) {
  margin-right: 0;
}

.product-description {
  margin-top: 15px;
}
.product-domain {
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #006fff;
}

.product-name {
  height: 30px;
  overflow: hidden;
  margin-top: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b4850;
}

.product-price {
  margin-top: 15px;
  position: relative;
}

.product-old-price {
  margin-right: 10px;
  width: 33px;
  height: 20px;
  text-decoration: line-through;
  font-family: 'gilroyMedium';
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #929292;
}

.product-filters {
  padding-top: 20px;
}
.product-filters h1 {
  margin-bottom: 7px;
  padding-left: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #312e34;
}

.product-new-price {
  width: 35px;
  height: 20px;
  font-family: 'gilroyMedium';
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #494458;
}
//.product-discount{
//	position:absolute;
//	right:0px;
//	bottom:1px;
//	display:inline-flex;
//	justify-content:center;
//	align-items:center;
//	width:45px;
//	height:26px;
//	border-radius: 4px;
//	background-image: linear-gradient(58deg, #6b3fdf 0%, #8f65ff 101%);
//	font-family: 'gilroyMedium';
//	font-size: 13px;
//	font-weight: 600;
//	font-stretch: normal;
//	font-style: normal;
//	line-height: 2.63;
//	letter-spacing: normal;
//	color: #ffffff;
//}

.product-image {
  display: flex;
  padding-top: 15px;
  justify-content: center;
}

.product-image img {
  width: 110px;
  height: 165px;
  object-fit: contain;
}

.manage-catalog {
  display: flex;
  padding-left: 15px;
  align-items: center;
  height: 55px;
  border-bottom: solid 1px #dfddee;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #312e34;
}

.add-new-product {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 61px;
  border-bottom: solid 1px #dfddee;
}
.import-from-club {
  border: 0;
}
.filters-container {
  display: flex;
  flex-direction: column;
}

.filters-container select {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4b4850;
}
.add-new-product_button {
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border: none;
  outline: none !important;
  width: 180px;
  outline: none;
  height: 40px;
  border-radius: 4px;
  background-color: #37dfb6;
}
.import-from-club_button {
  background: linear-gradient(0deg, #009ee3, #006fff);
}
.category-container {
  width: 100%;
  overflow-x: auto;
}
.category-wrapper {
  display: flex;
  height: 100%;
}
.category-heading {
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  border: solid 1px #dfddee;
  background-color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8;
  letter-spacing: normal;
  color: #272528;
}

.single-category-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8;
  letter-spacing: normal;
  color: #272528;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: #ececf4;
}

.category-listing {
  overflow: auto;
  height: 800px;
  border-top: solid 1px #dedcec;
  background-color: #ffffff;
}
//.MuiInput-underline::before{
//    content:"" !important;
//    left: 300px !important;
//}

.product-wizard-wrapper {
  height: 100%;
}

.tab-item {
  cursor: pointer;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  opacity: 1;
  color: #000000;
  font-size: 13px;
  font-family: 'RobotoRegular';
}

.tab-item:hover {
  font-family: 'RobotoMedium';
  background: rgba(118, 53, 243, 0.06);
  border-right: 2px solid #009ee3;
}
.tab-item:hover span {
  z-index: 100;
  opacity: 1;
  color: #009ee3 !important;
}

.tab-header {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.8;
  letter-spacing: normal;
  color: #272528;
  border-bottom: 1px solid #dfddee;
}

.tab-wrapper {
  position: relative;
  width: 201px;
  min-width: 201px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #dfddee;
}
.tab-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 15px;
}
.tab-buttons button {
  border: none;
  background: none;
  outline: none;
  width: 180px;
  height: 40px;
  border-radius: 4px;
  background-color: #6eb24b;
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.tab-buttons a button {
  margin-top: 5px;
  background-color: #c8c8ce;
}
.single-category-input {
  position: relative;
  height: 50px;
  border-radius: 4px;
}

.single-category-input i {
  position: absolute;
  right: 9px;
  top: 17px;
  cursor: pointer;
  z-index: 1;
}

.single-category-input input {
  padding-left: 10px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  opacity: 0.38;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.5;
  letter-spacing: normal;
  color: #545475;
}
.single-category {
  margin-right: 20px;
  width: 222px;
  border: 1px solid #dedcec;
  border-radius: 4px;
}
.category-name {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  padding-left: 28px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: #272528;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.category-name:hover {
  cursor: pointer;
  background-color: #009ee3;
  color: #ffffff;
}

.category-listing-wrapper {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
}

.product-wizard-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.product-attributes {
  width: 100%;
}

.app-container.mini-drawer .app-main-header {
  width: 100% !important;
}

.club-input__wrapper,
.club-select__wrapper,
.club-switch__wrapper,
.club-datepicker__wrapper {
  display: flex;
  align-items: center;
  height: 50px;
}

.club-checkbox__wrapper {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.club-input__wrapper input {
  padding: 11px;
  font-size: 13px;
  width: 475px;
}

.club-input__main,
.club-select__main,
.date-picker {
  margin: 10px 0 !important;
  fieldset {
    border-radius: 0;
    // border-color: $line-color-1 !important;
  }
  // &:not(:focus) {
  //     fieldset {
  //         // border-radius: 0;
  //         border-color: $line-color-1 !important;
  //     }
  // }
}

.club-input__wrapper span,
.club-select__wrapper span,
.club-switch__wrapper .club-switch__name,
.club-checkbox__wrapper .club-checkbox__name,
.club-datepicker__wrapper span {
  width: 120px;
  font-size: 13px;
}

.club-switch__wrapper .club-switch__name {
  text-transform: capitalize;
}

.club-import-products__button {
  width: 140px;
  margin-left: 15px;
}
.club-import-products__save-row {
  padding-top: 20px;
  height: 90px;
}
.club-loader {
  margin-top: 100px;
}

#select-merchant,
#select-brand,
#select-family {
  padding: 11px;
  width: 475px;
  font-size: 13px;
}

#menu-family ul li {
  font-family: 13px !important;
}

.product-countries__wrapper {
  display: flex;
}

.date-picker input {
  padding: 11px;
  margin: 0;
  cursor: pointer;
}

.date-picker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  position: relative;
}

.calendar-icon {
  margin-left: -25px;
}

@media (max-width: 1500px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 19.99677%;
    max-width: 30%;
  }
}

@media (max-width: 1350px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 24.99677%;
    max-width: 30%;
  }
}

@media (max-width: 1100px) {
  .headline-text {
    display: none;
  }
  .club-products-container .col-lg-2 {
    flex: 0 0 37.99677%;
    max-width: 33.3%;
  }
}

@media (max-width: 900px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 49.99677%;
    max-width: 53.3%;
  }
}

@media (max-width: 660px) {
  .club-products-container .col-lg-2 {
    flex: 0 0 104.99677%;
    max-width: 100.3%;
  }
}
.categories-form label {
  min-width: 150px;
}
.category_table thead tr th:nth-child(2) {
  padding-left: 22px;
}
.category_table .tablehead {
  max-width: 87%;
  height: 50px;
  /*border-bottom:1px solid rgba(224, 224, 224, 1);*/
}
.categorytable {
  padding-left: 0px;
}
.categorytable ul {
  display: block;
  min-height: 38px;
  padding: 0px 10px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%) !important;
  min-width: 0px;
  max-width: 100%;
  -webkit-border-radius: 3px;
  border-radius: 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  line-height: 24px;
  margin-bottom: 5px;
}
.categorytable ul:hover {
  background: #ffffff;
}
.category_child {
  margin-left: 50px;
  cursor: pointer;
}

.category_parent {
  cursor: pointer;
  background: #bcbfc1;
}
.category_parent li {
  width: 19.5% !important;
}
.category_child li {
  width: 20.1% !important;
}
.category_child li:first-child {
  width: 17% !important;
}

.category_table .tablehead li {
  width: 20%;
}

.app-wrapper {
  height: 100%;
  background: #fff;
}
.category_table_footer {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1450px) {
  .category_table .tablehead {
    max-width: 97%;
  }
  .category_child li {
    width: 20.09% !important;
  }
  .category_table .tablehead li {
    width: 17%;
  }
  .variation > div img {
    left: 6px !important;
  }
  .variation .cancel {
    left: 87px !important;
  }
}
@media (max-width: 800px) {
  .category_table .tablehead {
    max-width: 90%;
  }
  .category_table .tablehead li:first-child {
    padding-left: 10px;
  }
}
.tablehead li:nth-child(5) {
  padding-left: 150px;
}
.tablerow {
  padding-left: 0px;
  border: 1px solid rgba(224, 224, 224, 1);
}
.tablerow li {
  list-style-type: none;
  display: inline-block;
  width: 260px;
  padding-left: 20px;
}
.tablerow .actions {
  display: inline;
  max-width: 100px;
}
.tablehead li {
  list-style-type: none;
  display: inline-block;
  width: 256px;
  padding-left: 96px;
}
.tablehead li:last-child {
  width: 90px;
}
.tablehead {
  padding-left: 0px;
}
.category_table .tablehead {
  max-width: 87%;
  height: 50px;
  /*border-bottom:1px solid rgba(224, 224, 224, 1);*/
}

.disabled-link {
  pointer-events: none;
}

a[disabled] {
  pointer-events: none;
}

.import-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999999;
  padding: 30px 20px;
  overflow: hidden;

  .import-container {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 7px;
    overflow: hidden;
    .import-header {
      height: 78px;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background-color: #006fff;
      align-items: center;
      display: flex;
      .header-left {
        width: 30%;
        padding: 0px 30px;
        display: flex;
        align-items: center;
        h3 {
          font-family: 'Roboto';
          font-size: 18px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          margin-bottom: 0px;
        }
        .header-button {
          height: 40px;
          border-radius: 20px;
          border: solid 1px #ffffff;
          font-family: roboto;
          font-size: 13px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: normal;
          text-align: center;
          padding: 1px 14px 0;
          cursor: pointer;
        }
        .header-button:focus {
          outline: none;
        }
        .header-button.clear {
          background: transparent;
          margin-left: 30px;
          margin-right: 10px;
          color: #fff;
        }
        .header-button.import {
          color: #006fff;
        }
      }
      .header-right {
        width: 70%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .header-select {
          position: relative;
          cursor: pointer;
          margin-left: 10px;
        }
        .header-select::after {
          position: absolute;
          content: '';
          width: 4.8px;
          height: 5px;
          border: 1px solid #312e34;
          right: 20px;
          top: 15px;
          transform: rotate(45deg);
          border-left: none;
          border-top: none;
        }
        .searchbar {
          width: 429px;
          height: 36px;
          border-radius: 18px;
          background-color: #ffffff;
          position: relative;
          margin: 0 30px 0 10px;

          input {
            width: 429px;
            height: 36px;
            border-radius: 18px;
            background-color: #ffffff;
            border: none;
            padding: 10px 20px;
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4b4850;
          }
          input::placeholder {
            font-size: 13px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4b4850;
          }
          input:focus {
            outline: none;
          }
          .over-input {
            position: absolute;
            right: 20px;
            top: 8px;
          }
        }
        .close-modal {
          background: #fff;
          border-radius: 50%;
          margin-right: 20px;
          color: #000;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
    .import-body {
      padding: 20px 10px 100px 20px;
      display: flex;
      flex-wrap: wrap;
      overflow: scroll;
      height: 100%;
      position: relative;

      .selected-item {
        position: fixed;
        width: 506px;
        height: calc(100% - 60px);
        background: #fff;
        right: 20px;
        top: 30px;
        z-index: 999999;
        padding: 20px;
        transition: 3s ease-in-out;
        animation-name: slideIn;
        animation-duration: 1s;
        .selected-body {
          //overflow: scroll;
          height: 80%;
        }
        .close-selected {
          width: 24px;
          height: 24px;
          background-color: #312e34;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          margin-bottom: 15px;
          cursor: pointer;
        }
        .selected-up-title {
          font-family: 'Roboto', sans-serif;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #828282;
        }
        .selected-title {
          font-family: 'Roboto', sans-serif;
          font-size: 26px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d2932;
        }
        .selected-title-description {
          opacity: 0.59;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.79;
          letter-spacing: normal;
          color: #000000;
          padding-bottom: 20px;
          border-bottom: solid 1px rgba(151, 151, 151, 0.38);
        }
        .selected-options {
          border-bottom: solid 1px rgba(151, 151, 151, 0.38);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          h4,
          p {
            opacity: 0.84;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.79;
            letter-spacing: normal;
            color: #000000;
          }
        }
        .selected-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-items: center;
          position: fixed;
          bottom: 30px;
          right: 20px;
          width: 506px;
          max-width: 506px;
          padding: 20px 30px;
          transition: 3s ease-in-out;
          animation-name: slideIn;
          animation-duration: 1s;
          .selected-footer-left {
            width: 50%;
            display: flex;
            align-items: center;
          }
          p.selected-price {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
            margin-bottom: 0;
          }
          p.selected-oldprice {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #838383;
            text-decoration: line-through;
            margin-left: 20px;
            margin-bottom: 0;
          }
          button.add-selection {
            border-radius: 26px;
            background-color: #006fff;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;
            border: none;
            width: 197px;
            height: 52px;
          }
        }
        .selected-item-slider {
          width: 100%;
          height: 286.5px;
          position: relative;
          margin-bottom: 15px;
        }
        .selected-slider-overlay {
          width: 100%;
          height: 286.5px;
          position: absolute;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          top: 0;
          left: 0;
          .dots {
            width: 26px;
            height: 26px;
            background-color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-left: 10px;
            margin-bottom: 20px;
            cursor: pointer;
          }
          .dots.right {
            transform: rotate(180deg);
          }
        }

        img {
          width: 100%;
          height: 286.5px;
          transition: 0.5s;
        }
      }
    }
  }
}

@keyframes slideIn {
  from {
    right: -526px;
  }
  to {
    right: 20px;
  }
}

.greenBordered {
  border: 2px solid #54c08a !important;
}
.hovered-product-box {
  position: relative;
  cursor: pointer;
}
.over-product-box {
  button {
    opacity: 0;
  }
}

.over-product-box {
  display: flex;
  opacity: 0;
  transition: 0.5s;
  position: absolute;
  width: 95%;
  height: 300px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: rgba(65, 60, 79, 0.9);
  button {
    width: 151px;
    height: 39px;
    opacity: 1;
    border-radius: 20px;
    border: solid 1px #ffffff;
    font-family: 'gilroySemiBold';
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 11px 0px 10px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
  button:hover {
    color: #545061;
    background: #fff;
  }
  button:active {
    color: #545061;
    background: #fff;
  }
}
.hovered-product-box:hover .over-product-box {
  opacity: 1;

  z-index: 99;
}
.import-products-button {
  width: 180px;
  height: 40px;
  border-radius: 4px;
  background-image: linear-gradient(0deg, #009ee3, #006fff);
  color: #fff;
  font-family: 'gilroyMedium';
  font-size: 14px;
  font-weight: 600;
  border: 0;
}

@media screen and (max-width: 414px) {
  .search-bar.right-side-icon .search-icon {
    position: absolute;
    left: -8px !important;
    top: 2px !important;
  }

  .product-price {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    // padding-right: -7px;
  }

  //.category-wrapper {
  //  display: flex;
  //  flex-direction: column;
  //}
  //
  //.tab-wrapper-res {
  //  width: 100%;
  //  height: auto;
  //}

  //.tab-wrapper-content {
  //  width: 100% !important;
  //  height: auto;
  //}

  .flex {
    display: flex;
    flex-direction: row;
  }

  .translations-container-resp {
    width: 100% !important;
    height: auto !important;
  }

  .filters-container {
    display: flex;
    //flex-direction: row;
  }

  .filter-en {
    width: 150px !important;
  }
  .filter-source {
    width: 150px !important;
  }
}

.email-error__message {
  color: #ff0036;
  font-weight: bold;
}

@media (max-width: 550px) {
  .tab-wrapper-resp {
    width: 100%;
    height: 215px;
  }
}

.resp-filter-container {
  //background: red;
  width: 100%;
  height: 60px;
  display: none;
}

.resp-filter {
  //background: yellow;
  //float: right;
  //margin-top: 20px;
  //margin-right: 20px;
  //position: relative;
  //right: 10px;
  position: absolute;
  right: 25px;
  top: 70px;
  font-size: 25px;
}

@media screen and (max-width: 530px) {
  .club-m-resp {
    display: none;
  }

  .club-main-wrapper {
    width: 100%;
  }

  .resp-filter-container {
    display: block;
  }

  .club-m-resp {
    position: fixed;
    z-index: 200;
  }
}

@media screen and (max-width: 1070px) {
  .jr-card-input {
    width: 476px !important;
  }
}

@media screen and (max-width: 1320px) {
  .import-modal .import-container .import-header .header-left {
    width: 15%;
  }
  .import-modal .import-container .import-header .header-right {
    width: 85%;
  }
}

@media screen and (max-width: 1133px) {
  .import-modal .import-container .import-header {
    display: flex;
    flex-direction: column;
  }
  .import-modal .import-container .import-header .header-left {
    margin: 5px;
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }

  .import-modal .import-container .import-header .header-right {
    width: 100%;
  }
}

@media screen and (max-width: 915px) {
  .import-modal .import-container .import-header .header-right {
    display: flex;
    flex-direction: column;
  }

  .import-modal .import-container .import-header {
    height: auto;
  }

  .modal-resp {
    width: 400px !important;
    margin-bottom: 10px;
  }

  .import-modal .import-container .import-header .header-right .searchbar {
    width: 400px;
    margin: 0px 0px 10px 8px !important;
  }

  .close-modal {
    position: absolute;
    top: 40px;
    right: 20px;
  }
}

@media screen and (max-width: 700px) {
  .category-wrapper {
    display: flex;
    flex-direction: column;
  }

  .tab-wrapper-res {
    width: 100%;
    height: auto;
  }
  .tab-wrapper-content {
    width: 100% !important;
    height: auto;
  }
}

@media screen and (max-width: 550px) {
  .modal-resp {
    width: 250px !important;
  }

  .import-modal .import-container .import-header .header-right .searchbar {
    width: 250px;
  }

  .selected-item {
    width: 80% !important;
  }
  .selected-footer {
    right: 0px !important;
  }
  .selected-oldprice {
    font-size: 16px !important;
  }
  .selected-price {
    margin-left: 115px;
    font-size: 16px !important;
  }
  .add-selection {
    width: 150px !important;
    height: 50px !important;
  }
}

@media screen and (max-width: 400px) {
  .modal-resp {
    width: 150px !important;
  }

  .import-modal .import-container .import-header .header-right .searchbar {
    width: 150px;
  }

  .selected-price {
    margin-left: 155px;
  }
}
