/*Login Styles*/
/*Login Styles*/

.login-container {
  position: relative;
  height: 100%;
}

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(4px);
  font-size: 14px;
  max-width: 94%;
}

.login-header {
  margin-bottom: 30px;
}

.login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-login-content input[type=password]  {
  // padding-top: 15.29px;
  // padding-bottom: 15.29px;
  height: unset!important;
}
.app-login-container {
  height: 100%;
  position: relative;
  // max-width: 680px;
  width: 100%;
  margin: 0 auto;

  & .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
}

.app-login-main-content {
  @include display-flex();
  background-color: $white;
  @include box-shadow($shadow);
  @include border-radius(10px);
  font-size: 14px;
  overflow: hidden;
  z-index: 99;
}

.app-login-form input,
.app-forget-password input,
.app-signup-form input {
  padding-top: 13.5px;
  padding-bottom: 13.5px;
}

.app-signup-form label.Mui-focused,
.app-signup-form label.MuiFormLabel-filled,
.app-forget-password label.Mui-focused,
.app-forget-password label.MuiFormLabel-filled,
.app-login-form label.Mui-focused,
.app-login-form label.MuiFormLabel-filled {
  margin-top: 2px;
}

.app-forget-password label,
.app-login-form label,
.app-signup-form label {
  margin-top: 4px;
}

.app-login-form input,
.app-forget-password input,
.outlined-dense,
.app-signup-form input,
.app-forget-password input {
  height: unset!important;
  font-size: 13px;
}

.outlined-dense {
  font-size: 13px;
}

.login-error__message {
  margin-top: 8px;
  margin-bottom: 10px;
  color: #ff0036;
  font-size: 14px;
  font-weight: bold;
  font-family: 'gilroyBold';
}

.app-login-form {
  margin: 23px 30px;
  height: 245px;
  position: relative;
}

.app-login-main-content .envite-logo {
  width: 120px;
}

.app-login-form__lock {
  position: absolute;
  right: 0;
}

.app-login-content {
  width: 340px;
  height: 348px;
  border-radius: 8px;
}

.app-login-footer {
  background: #f6f9fc;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #9797973B;
  font-size: 13px;
  color: #2f2b2b;
}

.forgot-pass__link {
  text-decoration: underline;
  font-size: 13px;
  color: #676464!important;
  font-family: 'Roboto', sans-serif;
}

.sign-up__link {
  font-weight: bold;
  text-decoration: underline;
  color: #2f2b2b;
}

.app-login-header {
  margin-bottom: 30px;
}

.app-login-form h1 {
  font-family: 'gilroySemiBold';
  font-size: 16px;
  margin-top: 8px;
}

.login-button span {
  // box-shadow: 0 2px 0 0 rgba(107, 63, 223, 0.25);
}

.login-button a,
.login-button span {
  text-transform: capitalize;
  font-weight: bold;
}

.login-button,
.signup-button {
  width: 140px;
  height: 46px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 #c0c0c0;
  background-color: #0084C1 !important;
  color: white;
  border: none;
  border-radius: 4px;
}

.MuiInputBase-inputType-124,
.app-signup-form .sign-up__password {
  height: unset!important;
  line-height: unset!important;
  padding-bottom: 2px;
}

.textfield-placeholder {
  font-size: 13px!important;
}

.forgot-pass-login-button {
  margin-top: 8px;
}

.app-login-content .form-control {
  background-color: map_get($gray, lighten-4);

  &:focus {
    box-shadow: none;
    border-color: $app-primary;
  }
}

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important;
}

.app-logo-content {
  background-color: $app-primary;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2;

  @media screen and (max-width: 575px) {
    width: 100%;
    order: 1;
  }
}

.app-social-block {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);

  & .social-link,
  & .social-link li {
    margin: 0;
  }

  & .social-link button {
    border: solid 1px $app-primary;
    @include border-radius(50%);
    color: $app-primary;
    padding: 0;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary;
    }
  }

  & p {
    margin-bottom: 0;
  }
}


