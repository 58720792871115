.card-wrapper {
    color: $headings-color; 
    width: 100%;
    border: 1px solid $line-color-1;
    background-color: $secondary-2;
    border-radius: $border-radius;
    margin: 0 0 10px;
}

.card-wrapper ul li {
    font-size: 13px!important
}

.card-wrapper__header {
    height: 60px;
    line-height: 60px;
    font-size: 13px;
    padding-left: 24px;
    border-bottom: 1px solid $line-color-1;
}

.card-wrapper__content {
    padding: 0 20px 20px 20px;
}