.forgot-password__lock,
.lock-image__wrapper {
    width: 144px;
    margin: auto;
}

.rocket-image__wrapper {
    width: 227px;
    margin: 10px auto;
}

.app-forget-password {
    width: 340px;
    height: 398px;
    padding: 30px;
    box-sizing: border-box;
    position: relative;
}

.app-forget-password__sent {
    height: 451px;
    padding: 40px 30px;
}

.app-forget-password .login-button {
    width: 100%;
    margin-top: 5px;
    padding: 0;
}

.app-forget-password h1 {
    font-size: 20px;
    font-family: 'gilroyBold'
}

.app-forget-password p {
    font-size: 14px;
    height: 42px;
    font-weight: 500;
    color: #555555;
}

.app-forget-password h1,
.app-forget-password p {
    text-align: center;
    margin-bottom: 8px;
}

.app-forget-password .envite-logo {
    position: fixed;
    margin-top: 20px;
    margin-left: -30px;
    width: 100px;
}

.app-forget-password__sent .envite-logo {
    margin-top: 32px;
}

a.reset-password__text {
    color: white;
    text-decoration: none;
    display:inline-block;
    width: 100%;
    line-height: 46px;
    box-shadow: 0 2px 0 0 rgba(107, 63, 223, 0.25);
}

.app-forget-password .email-error__message {
    color: #ff0036;
    font-weight: bold;
}
