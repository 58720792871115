/*Avatar Styles*/
.user-avatar {
  margin-right: 8px;
  @extend %size-50;

  & img {
    max-width: 100%;
    height: auto;
  }

  &-lg {
    margin-right: 10px;
    @extend %size-60;
  }
}

.user-detail {
  & .user-name {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 10pt;
    text-transform: capitalize;
    line-height: 12pt;
    display: flex;

    i {
      transition: color .1s ease-out;
      font-size: 12pt;
      color: lighten($headings-color, 30%);
      margin-left: .5rem;
    }
  }

  & .user-description {
    font-size: 13px;
    margin-bottom: 0;
    color: $light-gray;
  }
}

.user-profile:hover, .user-profile:focus {
  & .user-name {
    i {
      color: lighten($headings-color, 0%);
    }
  }
}
