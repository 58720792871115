
.mui-custom {
  &_dialog {
    .MuiDialog-paper  {
    }
  }
}
.custom {
  &_attribute_values_card_box  {
    padding: 0 !important;
    .jr-card-body  {
      margin-top: 30px;
      .jr-card-label {
        width: 436px;

        button {
          margin-right: 10px;
        }
      }
      .attribute-cancel-button {
        flex: 0%;
        width: auto;;
        height: 40px
      }
      .atrribute-buttons {
        position: static;
      }
      .switcher {
        right: 0;
        top: 10px;
      }
    }
  }
  &-attribute_value-item {
    padding-top: 20px;
    position: relative;
  }
}