.users-wrapper {
  display: flex;
  height: 100%;
}

.users-wrapper > div:nth-child(1):not(.customers-container) {
  width: 201px;
  min-width: 201px;
}

.users-container {
  width: calc(100% - 200px);
  //   overflow-x: auto;
}

.users-card {
  padding-left: 20px;
  padding-right: 25px;
}

.table-responsive-material td {
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th {
  padding-left: 35px;
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th:nth-child(1) {
  padding-left: 0px;
}

.users-wizard {
  height: 100%;
  display: flex;
}

.attributes-wrapper {
  width: 100%;
}
.users-general {
  padding: 0 10px;
  padding-top: 30px;
}

.users-general h1 {
  font-family: 'RobotoRegular';
  padding-left: 25px;
  display: flex;
  height: 60px;
  margin-bottom: 0px;
  align-items: center;
  font-size: 13px;
  line-height: 42px;
  letter-spacing: 0;
  border-bottom: 1px solid rgb(222, 220, 236);
  color: rgb(39, 37, 40);
}
.users-general form {
  padding: 20px 30px;
  font-size: 13px;
  font-family: 'RobotoRegular';
  line-height: 50px;
  letter-spacing: 0;
  color: rgb(39, 37, 40);
}
.users-general form input {
  width: 475px;
  height: 41px;
  padding-left: 10px;
}

.users-general form span {
  width: 100px;
  display: inline-block;
}

@media (max-width: 950px) {
  .users-card {
    padding-left: 5px;
    padding-right: 5px;
    // overflow: auto;
  }
}

@media (max-width: 414px) {
  .users-wrapper {
    display: flex;
    flex-direction: column;
  }

  .users-resp {
    width: 100% !important;
    height: auto !important;
  }
  .users-container {
    width:100%;
  }
}
@media (max-width: 935px) {
  .jr-card {
    width: 90%;
  }}

@media (max-width: 835px) {
  //.jr-card {
  //  width: 90%;
  //}
  .users-general form input {
    width: 100%;
  }
  .users-general .custom-select {
    width: 100% !important;
  }
  .jr-card-label{
    //display: block;
    width: 100%;
  }
  .jr-card-input {
    width: 100%;
  }
}

@media (max-width: 665px) {
  .users-general form {
    padding: 0px !important;
  }

}

@media (max-width: 550px) {
  .users-wizard{
    display: flex;
    flex-direction: column;
  }
  .app-main-header{
    position: absolute;
    top: 0;
  }

  .jr-card-label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}


@media (max-width: 835px) {
  .jr-card-label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
