.logs-wrapper{
    display:flex;
    height:100%;
}

.logs-wrapper > div:nth-child(1):not(.logs-container){
    width: 201px !important;
    min-width: 201px;
}

.logs-container{
    width:100%;
    overflow-x:auto;
}

.logs-card{
    padding-left:20px;
    padding-right:25px;
}

.table-responsive-material td{
    cursor:pointer;
    border-left: solid 1px rgba(183, 185, 203, 0.37);
    text-align:center;
    width: auto;
    //white-space: nowrap;
    //overflow: hidden;
    //max-width: 200px;
    //text-overflow: ellipsis;
}

.logs-wrapper .table-responsive-material th{
    padding-left:14px !important;
    cursor:pointer !important;
    border-left: solid 1px rgba(183, 185, 203, 0.37);
    text-align:center !important;
}
.MuiToolbar-root, .MuiToolbar-regular{
    min-height:0px !important;
}
.table-responsive-material th:nth-child(1){
    padding-left:0px;
}

.logs-wizard{
    height:100%;
    display:flex;
}

.attributes-wrapper{
    width: calc(100% - 200px);
}
.logs-general{
   padding:0 10px;
}

.logs-general h1{
    font-family: 'RobotoRegular';
    padding-left:25px;
    display:flex;
    height:60px;
    margin-bottom:0px;
    align-items:center;
    font-size:13px;
    line-height:42px;
    letter-spacing:0;
    border-bottom:1px solid rgb(222,220,236);
    color: rgb(39,37,40)
}
.logs-general form{
    padding:20px 30px;
    font-size:13px;
    font-family: 'RobotoRegular';
    line-height:50px;
    letter-spacing:0;
    color:rgb(39,37,40);
}
.logs-general form input{
    width:475px;
    height:41px;
    padding-left:10px;
}

.logs-general form span{
width:100px;
display:inline-block;
}


@media (max-width: 950px) {
    .logs-card {
padding-left:8px;
padding-right:8px;
        overflow:auto;
    }

}
