.top-menu-enter {
    opacity: 0;
    transform: scale(1) translate3d(0, 0, 0) rotateX(90deg);
    transform-origin: top;
}

.top-menu-enter-active {
    opacity: 1;
    transform: translateX(0) translate3d(0, 0, 0) rotateX(0);
    transform-origin: top;
    transition: all .3s cubic-bezier(0, 0, 0.2, 1);
}

.top-menu-exit {
    opacity: 1;
    transform-origin: top;
}

.top-menu-exit-active {
    opacity: 0;
    transform: scale(1) translate3d(0, 0, 0) rotateX(90deg);
    
    transition: all .3s cubic-bezier(0, 0, 0.2, 1);
}