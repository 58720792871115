.switcher {
	position: relative;
	display: inline-block;
	margin-top: -6px;
	right: 475px;
}

.switcher input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #d8d0e0;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	cursor: pointer;
}

.slider:before {
	position: absolute;
	content: '';
	height: 24px;
	width: 24px;
	left: 2px;
	bottom: 1px;
	background-color: white;
	-webkit-transition: 0.2s;
	transition: 0.2s;
}

input + .slider {
	background-color: #dedcec;
	width: 53px;
	height: 26px;
}

input:checked + .slider {
	background-color: $secondary-3;
}

input:checked + .slider:before {
	transform: translateX(25px);
}

.slider.round {
	border-radius: 14px;
}

.slider.round:before {
	border-radius: 50%;
}

.j-c-start {
	justify-content: start !important;
}

.f-d-row {
	flex-direction: row !important;
}

.slider_actions a:hover{
	text-decoration: none;
}