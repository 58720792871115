/*Charts Styles*/

.chart-header {
  padding: 20px $jr-card-padding;

  & .card-title {
    h4 {
      color: $white;
      margin-bottom: 4px;
    }
  }

  & .title h4 {
    margin-bottom: 4px;
  }
  & p {
    margin-bottom: 4px;
  }
}

.recharts-responsive-container {
  position: relative;
}

.customized-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem 0;
}

button.legend-item {
  border: none;
  background-color: transparent;
  border-radius: 1rem;
  border: 1px dashed;
}

.little-charts__wrapper {
  padding: 0 15px;
}

.chats-activity__wrapper {
  height: 364px;
  width: 300px;
  padding-right: 5px;

  @media screen and (max-width: 991px) {
    padding-right: 0px;
    width: 300px;
    margin-top: 15px;
    order: 3;
  }

  @media screen and (max-width: 720px) {
    width: 100%;
  }
}

.top-products__wrapper {
  width: calc((100% - 300px) / 2);
  padding-right: 5px;
  padding-left: 5px;
  height: 364px;
  margin-bottom: 10px;

  @media screen and (max-width: 1400px) {
    width: calc((100% - 300px));
  }

  @media screen and (max-width: 991px) {
    width: calc(100% - 300px);
    padding-right: 15px;
    padding-left: 0;
    margin-top: 15px;
    order: 2;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 720px) {
    padding-right: 0;
    width: 100%;
  }
}

.countries-box {
  display: flex;
  flex-direction: row !important;
  padding: 0 !important;
  .countries-aside {
    width: 30%;
    height: 100%;
    background: #fff;
    border-right: 2px solid #f6f5fa;
    border-bottom-left-radius: 5px;

    .countries-heading {
      font-family: 'GilroySemiBold';
      font-size: 16px;
      font-weight: 600;
      color: #4e4b5d;
      padding: 18px 18px 0 18px;
    }
    .countries-list {
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 5px;

      .countries-list-item {
        list-style-type: none;
        span {
          width: 16px;
          height: 4px;
          border-radius: 2px;
          background-color: #f9777a;
          display: block;
          margin-bottom: 4px;
        }
        p {
          opacity: 0.75;
          font-family: 'GilroyBold';
          font-size: 12px;
          color: #4a4a4a;
          margin: 0;
          padding: 0;
          label {
            font-family: 'GilroyMedium';
          }
        }
      }
    }
    .countries-boxes {
      .country-box + .rounded {
        border-bottom-left-radius: 5px;
      }
      .country-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 19px;
        border-top: 2px solid #f6f5fa;
        cursor: pointer;
        h4 {
          font-family: 'GilroyMedium';
          font-size: 18px;
          color: #4e4b5d;
          padding: 0;
          margin: 0;
          text-align: end;
        }
        h5 {
          font-family: 'GilroyMedium';
          font-size: 12px;
          color: #6e6c7c;
          width: 50%;
          padding: 0;
          margin: 0;
          text-align: end;
        }
      }
    }
  }
  .countries-map {
    width: 75%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
  }
}

.top-members__wrapper {
  width: calc((100% - 300px) / 2);
  padding-left: 5px;
  height: 364px;

  @media screen and (max-width: 1400px) {
    width: 100%;
    padding-left: 0px;
  }
  @media screen and (max-width: 850px) {
    width: 100%;
    padding-left: 0px;
  }
}

.top-item-stats {
  @media screen and (max-width: 991px) {
    border-top: 1px solid #8080801f;
    margin-top: 15px;
  }
}

.carousel-control-prev {
  @media screen and (max-width: 992px) {
    left: -50px;
  }
}

.carousel-control-next {
  @media screen and (max-width: 992px) {
    right: -50px;
  }
}

.top-items__lines {
  padding-right: 0;
  padding-left: 0;
}

.carousel-caption h3 {
  padding: 20px 0 40px 0 !important;

  @media screen and (max-width: 992px) {
    padding: 0 0 0 0 !important;
  }
}

.chats-statistics__lines---header {
  margin: 4px auto !important;
}

.top-products__photo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.th-border-b tr:first-child {
  border-top: none;
}

.top-item-stats div {
  padding-top: 20px !important;
}

.envite-charts__cards--icon {
  position: absolute;
  right: 10px;
  top: 35px;
}

.envite-chart__cards .bg-white h1 {
  font-size: 20px;
  font-family: 'gilroyMedium';
  margin-bottom: 0 !important;
}

.envite-chart__cards .jr-fillchart,
.envite-chart__cards {
  height: 80px !important;
  margin-bottom: 0;
}

.envite-chart__cards .card-title .envite-charts__cards--icon:before {
  font-size: 20px;
  color: #c5c0d5;
}

.charts-cards__wrappers {
  margin-bottom: 15px;
}

.charts-cards__wrappers > div {
  height: 80px;

  @media screen and (max-width: 992px) {
    margin-bottom: 10px;
  }
}

.card-title__header {
  margin-top: -5px;
  margin-bottom: 0 !important;
  font-size: 13px;
}

.jr-fs-sm.envite-chart__cards--comment {
  font-size: 11px !important;
}

.price-container b {
  padding: 0;
  padding-right: 5px;
}

.dashboard-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 4px 4px -5px rgba(92, 88, 109, 0.46);
  background-color: #fefefe;
  padding: 18px;
}

.dashboard-card--small {
  height: 80px;
}

.dashboard-wrapper {
  background-color: #f6f5fa !important;
}

.noData {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  font-size: 18px;
}

.dashboard-card--small__value {
  font-family: 'gilroyMedium';
  font-size: 22px;
  font-weight: 500;
  color: #2f2b2b;
  margin: 0;
  padding: 0;
}

.dashboard-card--small__sub-value {
  font-family: 'gilroyMedium';
  font-size: 16px;
  color: #807f93;
  margin-left: 7px;
  cursor: pointer;

  &:hover {
    color: #009ee3;
  }
}

.dashboard-card--small__title {
  font-family: 'Roboto';
  font-size: 13px;
  color: #807f93;
  margin: 0;
  padding: 0;
}

.dashboard-card--small__percent {
  display: flex;
  p {
    font-family: 'gilroyMedium';
    font-size: 14px;
    padding: 0;
    margin: 0 10px 0 5px;
  }
  img {
    object-fit: contain;
  }
  .positive {
    color: #32a56e;
  }
  .negative {
    color: #d96363;
  }
}
.percent_green {
  color: #32a56e;
}
.percent_red {
  color: #d96363;
}
.dashboard-card--small__icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.dashboard--column {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.dashboard_main-chart {
  padding: 0 1rem;
}

.dashboard_main-chart_header {
  height: 89px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 33px;
}

.dashboard_main-chart_select {
  width: 184px !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 4px -5px rgba(92, 88, 109, 0.46) !important;
  border: solid 1px #dfddee !important;
  background-color: #fefefe !important;
}

.dashboard_main-chart_select_item {
  display: flex;
  line-height: 23px;
  img {
    margin-right: 5px;
  }
}
.dashboard-card--big {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: start;
}

@media screen and (max-width: 700px) {
  .dashboard-card--big {
    display: flex;
    flex-direction: column !important;
    height: 100%;
    align-items: start;
  }

  .countries-box .countries-aside {
    width: 100%;
  }
}

.dashboard-card--big_heading {
  font-family: 'gilroyMedium';
  font-size: 16px;
  font-weight: 600;
  color: #4e4b5d;
}

.dashboard-card_best-sellers__item {
  width: 100%;
}

.dashboard-card_best-sellers_image {
  width: 100%;
  height: 221px;
  object-fit: cover;
  align-self: center;
}

.dashboard-card_best-sellers_details {
  display: flex;
  justify-content: space-between;
}

.dashboard-card_best-sellers_details_header {
  font-family: 'gilroyMedium';
  font-size: 13px;
  font-weight: bold;
  line-height: 1.23;
  color: #009ee3;
  margin-top: 19px;
}

.dashboard-card_best-sellers_details_desc {
  font-family: 'Roboto';
  font-size: 13px;
  line-height: 1.15;
  color: #807f93;
  width: 60%;
  height: 30px;
  overflow: hidden;
}

.dashboard-card_best-sellers_details_arrows {
  display: flex;
  width: 40%;
  justify-content: flex-end;
}

.dashboard-card_best-sellers_details_arrow {
  width: 23px;
  height: 23px;
  background-color: #c5c0d5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  color: white;
  font-size: 22px;
  cursor: pointer;
}

.dashboard-card--big_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.dashboard-card--big_show-all {
  font-family: 'gilroyMedium';
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  color: #5e77ff;
}

.dashboard-card_last-orders_header {
  display: flex;
  align-items: center;
  border-top: 1px solid #f6f5fa;
  border-bottom: 1px solid #f6f5fa;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  :first-child {
    width: 41%;
  }
  p {
    margin: 0;
    width: 19.6%;
    display: flex;
    align-items: center;
    //flex-grow: 1;
    opacity: 0.74;
    font-family: 'Roboto';
    font-size: 11px;
    font-weight: 500;
    color: #4e4b5d;
  }
}
.dashboard-card_last-orders_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 69px;
  border-bottom: 1px solid #f6f5fa;
  p {
    margin: 0;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-size: 12px;
  }
  .color {
    color: #665fe7;
  }
}

.dashboard-card_last-orders_item_image {
  width: 7%;
  margin-right: 3%;
  object-fit: cover;
  height: 56px;
}
.dashboard-card_last-orders_item_desc {
  width: 30%;
  height: 100%;
  padding-right: 3%;
}
.dashboard-card_last-orders_item_other {
  width: 19%;
  height: 100%;
}

.dashboard-custom-tooltip {
  width: 211px;
  box-shadow: 0 2px 28px -7px rgba(71, 70, 91, 0.21);
  border: solid 1px #dfdce8;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  .tooltip-header {
    display: flex;
    align-items: center;
    .header-left {
      width: 65%;
      text-align: left;
      h4 {
        font-family: Roboto;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(47, 43, 43, 0.51);
        margin-bottom: 0;
        margin-top: 0;
      }
      h2 {
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4e4b5d;
        margin-bottom: 10px;
        margin-top: 10px;
      }
      h5 {
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4e4b5d;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
    .header-right {
      text-align: center;
    }
  }
  .tooltip-merchants {
    padding-top: 25px;
  }
  .merchant-item {
    display: flex;
    align-items: center;
    padding-top: 10px;

    .item-left {
      width: 70%;
      text-align: left;
      h4 {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #009ee3;
        margin-top: 0;
        margin-bottom: 0;
      }
      span {
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a8891;
      }
    }
    .item-right {
      text-align: right;
      span {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4e4b5d;
      }
    }
  }
}

.average-bubble {
  top: -16px;
  left: 20px;
  height: 34px;
  position: absolute;
  background: #009ee3;
  border-radius: 3px;
  padding: 8px;

  p {
    font-family: Roboto;
    font-size: 13px;
    color: #ffffff;
  }
}

.average-bubble:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top-color: #009ee3;
  border-bottom: 0;
  margin-left: -7px;
  margin-bottom: -7px;
}

@media (min-width: 992px) and (max-width: 1400px) {
  // .dashboard-card--wrapper {
  //   flex: 0 0 33.33% !important;
  //   max-width: 33.33% !important;
  //   margin-bottom: 10px;
  // }
}

.app-toolbar .date-range-picker__btn {
  border: 1px solid #dfddee;
  border-radius: 5px !important;
  padding: 10px !important;
  width: 270px;
}

@media screen and (max-width: 530px) {
  .app-toolbar .date-range-picker__btn {
    display: none;
  }
}

.app-toolbar .date-range-picker__btn:focus {
  background-color: rgba(0, 0, 0, 0.08);
}
.date-range__picker--no-padding,
.date-range__picker--no-padding * {
  padding: 0 !important;
}

.date-range__picker--wrapper {
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: row;
  top: 10px;
  box-shadow: 0 2px 28px -7px rgba(71, 70, 91, 0.21);
  border: solid 1px #dfdce8;
  border-radius: 4px;
  padding: 0;
  z-index: 100;
}

.date-range__picker--wrapper .DateRangePicker__CalendarSelection {
  background-color: #009ee3;
  border: 1px solid #009ee3;
}

.date-range__picker--wrapper .DateRangePicker__CalendarHighlight--single {
  border: 1px solid #009ee3;
}

.date-range__picker--wrapper {
  background-color: #fcfbfc;
}

.date-range__picker--left {
  width: 120px;
  padding: 5px 0;
}

.date-range__picker--left p {
  color: black;
  margin: 5px 0;
  font-family: Roboto;
  font-size: 13px;
  cursor: pointer;
  padding: 1.5px 0 1.5px 25px;
  color: #60596c;
}

.date-range__picker--left h4 {
  padding-left: 10px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

.date-range__picker--left .active-selected__period {
  font-weight: bold;
  background-color: rgba(128, 127, 147, 0.1);
  color: black;
}

.date-range__picker--right {
  border-left: solid 1px #dfdce8;
  border-radius: 4px;
  padding-top: 3px;
  padding-bottom: 3px;
  background: white;
  box-shadow: 0 2px 28px -7px rgba(71, 70, 91, 0.21);
  height: 310px;
}

.date-range__picker--btns {
  padding-right: 20px;
  display: flex;
  width: 630px;
  justify-content: flex-end;
  position: absolute;
  bottom: 5px;
}

.date-range__picker--btns button {
  width: 78px;
  height: 36px;
  border-radius: 5px;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 13px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
}

.date-range__picker--cancelBtn {
  background-color: #ecebee;
}

.date-range__picker--cancelBtn:hover {
  background-color: #d8d8d8;
}

.date-range__picker--updateBtn {
  background-color: #009ee3;
  color: white;
  margin-left: 10px;
}

.date-range__picker--updateBtn:hover {
  background-color: #006fff;
}

.date-range__picker--left .active-selected__period {
  font-weight: bold;
  background-color: rgba(128, 127, 147, 0.1);
  color: black;
}

.active-selected__period:before {
  content: '\2713';
  display: inline-block;
  vertical-align: top;
  line-height: 1em;
  width: 1em;
  height: 1em;
  text-align: center;
  color: black;
  margin-left: -18px;
  margin-right: 4px;
  margin-top: 2px;
}

.header--transparent {
  background: #f6f5fa;
}

@media screen and (max-width: 414px) {
  .countries-box .countries-aside .countries-boxes .country-box + .rounded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .countries-box .countries-aside .countries-boxes .country-box {
    display: flex;
    padding: 10px;
    //flex-direction: column;
    align-items: center;
    //justify-content: center;
  }

  .dashboard-card_last-orders_item_desc {
    display: none !important;
  }

  .dashboard-card_last-orders_header {
    justify-content: space-around;
  }

  .dashboard-card_last-orders_header :first-child {
    width: auto;
  }

  .top-products__wrapper {
    height: 376px;
  }
}

@media only screen and (min-width: 531px) {
  .club-m-resp {
    display: block !important;
  }
}

@media screen and (max-width: 530px) {
  //.catalog-container{
  //  display: flex;
  //  flex-direction: column !important;
  //}
  //.club-m{
  //  width: 100% !important;
  //  height: auto;
  //}
  //.club-main-wrapper{
  //  width: 100% !important;
  //}
  //
  //.merchant-resp{
  //  display: flex;
  //  flex-direction: column;
  //}
  //
  //.tab-wrapper{
  //  width: 100% !important;
  //}
  //
  //.manage-slider-resp{
  //  width: 100% !important;
  //}

  .club-m-resp {
    display: none;
  }
}
