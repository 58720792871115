.dashboard-icon {
    background-image: url('../images/navigation/npDashboard572826000000@3x.png');
    display: inline-block;
    background-size: cover;
    width: 21px;
    height:13px;
    
}
.open .dashboard-icon {
    background-image: url('../images/navigation/npDashboardblue.png');
    display: inline-block;
    background-size: cover;
    width: 21px;
    height:13px;

}
.app-icon {
    background-image: url('../images/navigation/npApp2754232000000@3x.png');
    display: inline-block;
    background-size: cover;
    width: 20px;
    height:22px;

}

.open .app-icon {
    background-image: url('../images/navigation/npAppblue.png');
    display: inline-block;
    background-size: cover;
    width: 20px;
    height:22px;

}
.club-icon {
    background-image: url('../images/navigation/grayx.png');
    display: inline-block;
    background-size: cover;
    width: 18px;
    height:18px;

}
.open .club-icon {
    background-image: url('../images/navigation/nounTag19216850000001@3x.png');
    display: inline-block;
    background-size: cover;
    width: 18px;
    height:18px;

}
.heart-icon {
    background-image: url('../images/navigation/nounLove2037525000000@3x.png');
    display: inline-block;
    background-size: cover;
    width: 18px;
    height:17px;

}
.open .heart-icon {
    background-image: url('../images/navigation/nounLoveblue.png');
    display: inline-block;
    background-size: cover;
    width: 18px;
    height:17px;

}
.bank-icon {
    background-image: url('../images/navigation/nounBank738674000000@3x.png');
    background-size: cover;
    display: inline-block;
    width: 16px;
    height:18px;

}
.open .bank-icon {
    background-image: url('../images/navigation/nounBankblue.png');
    background-size: cover;
    display: inline-block;
    width: 16px;
    height:18px;

}
.cart-icon {
    background-image: url('../images/navigation/nounCart2043489000000@3x.png');
    display: inline-block;
    background-size: cover;
    width: 18px;
    height:19px;

}

.open .cart-icon {
    background-image: url('../images/navigation/nounCartblue.png');
    display: inline-block;
    background-size: cover;
    width: 18px;
    height:19px;


}
