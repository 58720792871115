.app-signup-main-content {
    background-color: $white;
    font-size: 14px;
    width: 630px;
    padding: 40px 60px;
    box-sizing: border-box;
    overflow: auto;
    z-index: 999;
    height: 100vh;
    @media screen and (max-width: 1060px) {
        padding: 20px 30px;
      }

    @media screen and (max-width: 768px) {
        width: 100%;
        flex-wrap: wrap;
      }
}

.signup-button {
    width: 100%;
    margin: 25px 0 20px 0!important;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 4px;
  box-shadow: 0 2px 4px 0 #c0c0c0;
  background-color:#0084c1;
}

.signup-button span {
    text-transform: capitalize;
    font-weight: bold;
}

.app-signup-main-content .back-to-login {
    width: 135px;
    height: 39px;
    background-color: #f4f3f6;
    padding: 0!important;
    box-shadow: 0 2px 0 -1px rgba(0, 0, 0, 0.07);
    border: solid 1px rgba(0, 0, 0, 0.13);
}

.back-to-login a span {
    font-size: 13px;
}

.back-to-login a {
    color: #2f2b2b;
    text-decoration: none;
    display: inline-block;
    width: 100%;
    line-height: 39px;
    text-transform: capitalize;
    font-family: 'gilroyBold';
    font-size: 14px;
}

.app-signup-form .sign-up__terms {
    text-align: center;
    font-size: 12px;
}

.first-last-names__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.sign-up__input--wrapper {
    font-size: 11px;
    color: red;
    top: -8px;
    line-height: 9px
}

.sign-up__error {
    position: relative;
}

.sign-up__firstname,
.sign-up__lastname {
    width: 48%!important;
    font-size: 13px;
}

.app-signup-main-content h1 {
    font-family: 'gilroyBold';
    font-size: 22px;
    margin: 50px 0 10px 0;
    color: #2f2b2b;
}

.app-signup-main-content p {
    font-size: 14px;
}

.input-error fieldset {
    border: 1px solid red!important;
}

.input-error label {
    color: red!important;
}

.app-signup-form .outlined-dense {
    width: 100%;
    margin: 10px 0;
}

.outlined-dense label {
    font-size: 13px;
    color: black,
}

.sign-up__terms {
    margin: 0;
    font-size: 12px;
    text-align: center;
}

.sign-up__terms span {
    color: #0056ff;
    text-decoration: underline;
    cursor: pointer;
  }

  .sign-up_layout{
    display: flex;
    width: 100%;

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        overflow: scroll;
    }
    
  }
  .sign-up_background {
    width: calc(100% - 631px);
    height: 100%;
    padding: 50px 0 90px 90px;

    @media screen and (max-width: 1368px) {
        width: 100%;
        padding: 40px 0 90px 27px;
      }
    
    .background-container{
        padding: 50px 0 122px 0;
    background-size: 72%;
    background-repeat: no-repeat;
    background-position: 100% 74%;
    height: 100%;

    @media screen and (max-width: 1368px) {
        background-position: 135% 100%;
      }
    }
    @media screen and (max-width: 760px) {
        width: 100%;
      }
    
    .clalit_logo {
        width: 120px;
    }
    h3 {
        font-family: 'gilroyMedium';
        font-size: 28px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #ffffff;
        margin-top: 40px;
        @media screen and (max-width: 1368px) {
            font-size: 20px;
          }
    }
  }
  .sign-up_list {
      margin-left: 0;
      padding-left: 0;
      margin-top: 40px;
      li {
          list-style-type: none;
          display: flex;
          margin-bottom: 35px;
          align-items: center;
                            img {
                                width: 53.7px;
                                height: 53.7px;
                                margin-right: 35px;

                                        @media screen and (max-width: 1368px) {
                                            width: 43.7px;
                                            height: 43.7px;
                                            margin-right: 25px;
                                        }
                                        }
                            }
                        p {
                            font-family: 'gilroyMedium';
                            font-size: 22px;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.1;
                            letter-spacing: normal;
                            color: #ffffff;
                            max-width: 160px;
                            margin-bottom: 0;
                            @media screen and (max-width: 1368px) {
                                font-size: 16px;
                            }
                            
                        

      }
  }

  