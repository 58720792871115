@font-face {
    font-family: 'gilroyBold';
    src: url('./Gilroy-Bold.ttf')
  }

@font-face {
    font-family: 'gilroyMedium';
    src: url('./Gilroy-Medium.ttf')
  }

@font-face {
    font-family: 'gilroySemiBold';
    src: url('./Gilroy-SemiBold.ttf')
}

@font-face {
    font-family: 'RobotoRegular';
    src: url('./Roboto-Regular.ttf')
}

@font-face {
    font-family: 'RobotoMedium';
    src: url('./Roboto-Medium.ttf')
}