/* Dashboard Cards */
.app-wrapper.d-flex.justify-content-center {
    margin: 10px;
}
.app-wrapper .mb-4,
.app-wrapper .my-4 {
    margin-bottom: 10px!important;
}
.app-wrapper .jr-fillchart-content {
    padding: 10px;
}

/* Radial Chart */
.radial-chart .jr-card  {
    padding: 10px 0!important;
}

.radial-chart .card-heading,
.top-influencers .card-heading  {
    margin: 10px 0 -20px 15px;
}

.radial-chart .new-customers__count {
    margin: 0 0 0 15px;
}

/* Top Influencers */

.top-influencers .jr-card:first-child {
    padding-top: 5px;
}

.top-influencers .jr-card {
    margin-bottom: 0;
    padding: 0;
}

.col-xl-12.col-12 .jr-card {
    border-top-left-radius: 0;
    border-top-right-radius: 0; 
    padding-top: 0;
}

.top-influencers .table-sm th, 
.top-influencers .table-sm td {
    padding: 2px 0.3rem;
}

.top-influencers .user-profile {
    padding: .15rem 0;
}

.top-influencers .user-avatar {
    width: 40px!important;
    height: 40px!important;
}

.top-influencers .default-table thead th {
    font-size: 12px !important;
}

/* Activity Chart */

.top-influencers {
    padding: 0!important;
}


/* Top Items */
.top-items .jr-card-header .card-heading {
    display: flex;
    justify-content: center;
}
.top-items .jr-card {
    padding: 0;
}

.top-items .jr-card:first-child {
    height: 284px;
    padding-top: 5px;
}

.top-items .carousel-inner {
    max-height: 220px!important;
}

.top-items .carousel-inner {
    max-height: 200px;
}

.item-link {
    z-index: 9999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
}

.top-item__div {
    width: 200px;
    height: 220px;
    margin: auto;
}

.top-items .text-black {
    position: absolute;
    bottom: -40px;
}

.carousel-control-prev-icon, 
.carousel-control-next-icon {
    position: absolute;
    bottom: 11px;
    background-image: gray;
    border-radius: 50%;
    background-image: url('https://image.flaticon.com/icons/svg/32/32213.svg')
}
.carousel-control-prev-icon {
    transform: rotate(180deg)
}

.recharts-legend-wrapper {
    overflow-x: auto;
}

.top-item-stats p {
    font-size: 12px;
}

.top-item-stats .val {
    font-size: 19px;
}

.br-custom-1 {
    border-right: 1px solid #8080801f;
}
.bb-custom-1 {
    border-bottom: 1px solid #8080801f;
}
/* Cards Top */
div.cards-top div.jr-card {
    padding: 16px 15px 10px 15px;
}

.cards-top .row {
    height: 126px;
}

.cards-top.row .jr-card {
    padding: 0;
}

.col-1-5 {
    width: 20%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
/* #region Top 5 Influencers */

.table-responsive-material-header {
    width: 100%;
    display: flex;
    font-size: 14px;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
    color: black;
    font-weight: bold;
    padding: 10px;
}
.table-responsive-material-header span {
    margin-left: 10px;
}
.table-responsive-material {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    /*margin-bottom: 20px;*/
}
.top-influencers-table {
    width: 100%;
}
.top-influencers-table tr {
    border-top: 1pt solid rgba(128, 128, 128, 0.2);
}
.top-influencers-table tr td:first-child {
    padding: 0;
    padding-left: 1rem
}
.top-influencers-board__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 3.5px 0;
}
/* #endregion Top 5 Influencers */

.h-c {
    font-weight: 600;
}

.c-progressbar.absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.c-progressbar.c-bgc-white {
    background-color: #ffffff6e;
}

.c-progressbar .c-bar {
    /* border-radius: 10px; */
    background-color: #ffffff91 !important;
}

@media only screen and (max-width: 767px) {
    .card-header {
        flex-direction: column;
    }
}