.merchant-wrapper {
  display: flex;
  height: 100%;
}

.merchant-wrapper > div:nth-child(1) {
  width: 201px;
  min-width: 201px;
}

.merchant-container {
  width: calc(100% - 200px);
  // overflow-x: auto;
}

.merchant-card {
  //padding-left: 20px;
  //padding-right: 25px;
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
}

.MuiPaper-elevation1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.table-responsive-material td {
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th {
  padding-left: 35px;
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th:nth-child(1) {
  padding-left: 0px;
}

.merchant-wizard {
  height: 100%;
  display: flex;
}

.attributes-wrapper {
  width: calc(100% - 200px);
}

.merchant-box {
  width: 630px;
}

.club-add-merchant-content {
  padding: 0 10px;
  margin-top: 15px;

  .jr-card-g {
    background: #ffffff;
    border: solid 1px #dedcec;
    margin-bottom: 20px;
  }
  .jr-card-body {
    padding: 25px;
  }

  .add-new-product {
    justify-content: flex-start;
    padding-left: 15px;
    border-bottom: none;
  }

  h1 {
    font-family: "RobotoRegular";
    display: flex;
    height: 60px;
    margin-bottom: 0px;
    align-items: center;
    font-size: 13px;
    line-height: 42px;
    letter-spacing: 0;
    border-bottom: 1px solid rgb(222, 220, 236);
    color: rgb(39, 37, 40);
  }
}

.club-add-merchant-content form,
.merchant-modal-form {
  padding: 20px 15px;
  font-size: 13px;
  font-family: "RobotoRegular";
  line-height: 50px;
  letter-spacing: 0;
  color: rgb(39, 37, 40);
}
.merchant-modal-form input {
  width: 450px;
  height: 41px;
  padding-left: 10px;
}
.merchant-modal-form span {
  width: 205px;
  display: inline-block;
}
.club-add-merchant-content form input {
  width: 475px;
  height: 41px;
  padding-left: 10px;
}

.club-add-merchant-content form span {
  width: 115px;
  display: inline-block;
}

.merchant-images {
  padding: 20px;
}

.merchant-images label {
  padding-left: 63px;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  outline: none;
  border: none;
  width: 213px;
  height: 41px;
  box-shadow: 0 2px 0 0 rgba(107, 63, 223, 0.25);
  background-image: linear-gradient(0deg, #009ee3, #006fff);
  line-height: 2.85;
  letter-spacing: normal;
  color: #ffffff;
  font-family: "RobotoRegular";
  display: table;
}
.countries {
  overflow-y: scroll;
  max-height: 400px;
  div {
    cursor: pointer;
    padding: 5px 25px;
    &:hover {
      background: #f5f5f5;
    }
  }
}
input[type="file"] {
  display: none;
}

// Upload Merchant Image
.merchant-uploaded-imagebox {
  width: 260px;
  height: 260px;
  position: relative;
}
.merchant-image-thumbnail {
  width: 100%;
}
.merchant-remove-thumbnail {
  position: absolute;
  right: 0;
  top: -15px;
}

.form-group {
  display: flex;
  align-items: center;

  .svg-box {
    margin-left: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg:active {
      fill: #000000;
    }
  }
}

.plugin-instructions__step {
  margin-bottom: 30px;
}

.plugin-instructions__text {
  font-size: 16px;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji;
  color: #24292e;
}

.plugin-instructions__text--important {
  font-weight: bold;
}

.plugin-instructions__text--positive {
  color: green;
}
.plugin-instructions__text--negative {
  color: red;
}

.status-mapper {
  display: flex;
}
.status-mapper__section {
  margin-right: 40px;
}
.status-mapper__input {
  display: block;
  width: 250px;
  margin-left: 0;
  margin-top: 20px;
}
.status-mapper__add {
  width: 50px;
  height: 50px;
  margin-top: 20px;
  border: 1px solid #dfddee;
  cursor: pointer;
}
.status-mapper__add__icon {
  color: #ae92fa7d;
  width: 50px !important;
  height: 50px !important;
}

.status-mapper__add__icon:hover {
  color: #ae92fa;
}

.merchant-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row !important;
}

.merchant-image {
  padding-top: 0;
}

.merchant-placeholder-image {
  font-size: 8rem;
  color: #dfddee;
}

.merchant-box {
  //flex: 0 0 20% !important;
  //max-width: 20% !important;
  //width: 100px;
}

.status-mapper-header {
  display: flex;
  margin-top: 20px;
}
.status-mapper-header__section {
  width: 290px;
}

.status-mapper__delete {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  align-self: center;
  margin-top: 20px;
  cursor: pointer;
}

@media (max-width: 1250px) {
  .merchant-placeholder-image {
    font-size: 6rem;
  }
}

@media (max-width: 1070px) {
  .club-add-merchant-content form input {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .jr-card-input {
    width: 70%;
  }
  .merchant-card {
    padding-left: 0px;
    padding-right: 0px;
    //overflow: auto;
  }
  .merchant-box {
    //flex: 0 0 33.33% !important;
    //max-width: 33.33% !important;
  }
  .merchant-placeholder-image {
    font-size: 6rem;
  }
  .MuiPaper-elevation1 {
    //margin-left: 10px;
    //margin-right: 10px;
  }
}

.merchant-showHideMenu {
  display: none;
}

@media screen and (min-width: 851px) {
  .merchant-wrapper-resp {
    display: block !important;
  }

  .categories-wrapper-resp {
    display: block !important;
  }
}

@media (max-width: 850px) {
  .categories-wrapper-resp {
    display: none;
    z-index: 200;
    position: absolute;
  }

  .merchant-wrapper-resp {
    display: none;
    position: absolute;
    z-index: 200;
  }

  .merchant-showHideMenu {
    display: block;
    position: absolute;
    top: 80px;
    right: 10px;
    font-size: 30px;
  }

  .merchant-box {
    width: 100%;
  }

  .attributes-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 650px) {
  .merchant-wrapper {
    display: flex;
    flex-direction: column;
  }

  .merchants-resp {
    width: 100% !important;
  }
}

@media (max-width: 650px) {
  .merchant-card {
    //width: 80% !important;
  }

  .merchant-resp {
    display: flex;
    flex-direction: column;
  }

  .slider-resp {
    width: 100% !important;
    height: 235px;
  }
  .manage-slider-resp {
    width: 100% !important;
  }
  .merchant-container {
    width: 100%;
  }
}

@media (max-width: 650px) {
  //.merchant-wrapper {
  //  display: flex !important;
  //  flex-direction: column;
  //}
  .merchant-box {
    //flex: 0 0 50% !important;
    //max-width: 50% !important;
  }

  //.merchant-container {
  //  width: 100% ;
  //}
  //.merchants-resp {
  //  width: 100% !important;
  //  height: auto !important;
  //}
  .app-toolbar .search-bar input {
    padding: 0.55rem 2rem;
  }

  //.merchant-card{
  //  width: 100% !important;
  //}
}
