.customers-wrapper {
  display: flex;
  height: 100%;
}

.customers-wrapper > div:nth-child(1) {
  width: 201px !important;
  min-width: 201px;
}

.customers-container {
  width: 100%;
  //overflow-x: auto;
}

.customers-card {
  padding-left: 20px;
  padding-right: 25px;
  margin-top: 30px;
}

.table-responsive-material td {
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
  height: 75px;
  //padding: 0px;
}

.table-responsive-material th {
  padding-left: 35px;
  cursor: pointer;
  border-left: solid 1px rgba(183, 185, 203, 0.37);
  text-align: center;
}

.table-responsive-material th:nth-child(1) {
  padding-left: 0px;
}

.customers-wizard {
  height: 100%;
  display: flex;
}

.attributes-wrapper {
  width: calc(100% - 200px);
}

.customers-general {
  padding: 0 10px;
}

.customers-general h1 {
  font-family: 'RobotoRegular';
  padding-left: 25px;
  display: flex;
  height: 60px;
  margin-bottom: 0px;
  align-items: center;
  font-size: 13px;
  line-height: 42px;
  letter-spacing: 0;
  border-bottom: 1px solid rgb(222, 220, 236);
  color: rgb(39, 37, 40);
}

.customers-general form {
  padding: 20px 30px;
  font-size: 13px;
  font-family: 'RobotoRegular';
  line-height: 50px;
  letter-spacing: 0;
  color: rgb(39, 37, 40);
}

.customers-general form input {
  width: 475px;
  height: 41px;
  padding-left: 10px;
}

.customers-general form span {
  width: 100px;
  display: inline-block;
}

.import-customers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //align-items: center;
  margin: 10px;
  height: auto;
  flex-grow: 1;
  border-bottom: solid 1px #dfddee;
}

.import-customers__heading {
  font-family: gilroySemiBold;
  font-size: 16px;
  align-self: center;
  //color: #37dfb6;
}

.import-customers__upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.import-customers__file-input {
  display: flex !important;
  width: 180px;
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer !important;
}

.import-customers__file-name {
  font-family: GilroyMedium;
  font-size: 14px;
  margin-top: 15px;
}

.import-customers__upload-btn {
  margin-top: 10px;
  background-image: linear-gradient(0deg, #009ee3, #006fff);
}

.table-actions {
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  //background: red;
}

.table-actions__block {
  display: flex;
  align-items: center;
  justify-content: center;
  //width: 50px;
  //height: 25px;
  padding: 5px 8px;
  border-radius: 3px;
  background-color: rgb(255, 0, 79);
  color: #fff;
  font-family: gilroySemiBold;
  cursor: pointer;
}

.table-actions__block--unblock {
  background-color: #4caf50;
}

.table-row--disabled {
  opacity: 0.5;
}

.table-row--disabled:hover {
  opacity: 1;
}

.customers-loader {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (max-width: 950px) {
  .customers-card {
    //padding-left: 0px;
    //padding-right: 0px;
    overflow: auto;
  }
}
