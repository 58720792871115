.enhanced-table-head-wrapper {
    background-color: #fbfbfd;
}
.MuiTableBody-root {
    background-color: #fff;
}

.table-responsive-material td {
    border-color: #e1e2ea;
}

.Mui-selected {
    background-color: #37dfb6 !important;
}

.Mui-checked {
    color: #fff !important;
}