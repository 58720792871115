/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  padding: 20px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
}

// .bg-1 {
//   background-image: url(../../assets/images/background-1.svg);
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
// }
