/*Sidebar Styles*/

.scrollbar {
  background-color: $sidebar-bg;
}

.side-nav {
  width: $side-nav-width;

  //background-color: $sidebar-bg !important;
  // color: $sidebar-text-color !important;
  // border-right: 0 none !important;
  // z-index: 1250 !important;

  & .user-profile {
    background-color: $sidebar-bg-darken;
    padding: 13px 20px;
  }

  & .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px;
  }

  & .user-detail {
    & .user-name {
      color: $sidebar-hover-color;
      cursor: pointer;
      font-size: $font-size-base;
      font-weight: 400;
      white-space: nowrap;
    }
  }
}

.app-sidebar.minimized .side-nav,
.app-sidebar.minimized .scrollbar,
.app-sidebar.minimized .scrollbar > div {
  overflow: visible !important;
}

.user-detail {
  & .user-name {
    cursor: pointer;
  }
}

ul.nav-menu {
  position: relative;
  font-size: $font-size-base;
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

ul.nav-menu li {
  position: relative;
  // margin-top :33px;
  overflow: hidden;
}

ul.nav-menu li.menu {
  padding: 0 !important;
  & + .nav-header {
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    border-top: solid $border-width lighten($sidebar-bg, 6%);
  }
  & .sub-menu {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }
  &.open > .sub-menu {
    max-height: 1500px;
    transition: max-height 0.25s ease-in;
  }

  &.open {
    & .menu.active > .sub-menu {
      display: block;
    }
  }
}

ul.nav-menu li button,
ul.nav-menu li a {
  // width: 100%;
  color: $sidebar-text-color;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  min-width: inherit !important;
  padding: 1.2rem 0.6rem !important;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  box-shadow: inset 0 0 0 #009ee3;
  transition: all 0.1s ease-out;
  // background-color: $sidebar-bg;
  min-height: 3.3rem;
  @include border-radius(0);

  .nav-text {
    font-weight: 600;
    font-size: 11pt;
    color: #000000;
    transition: color 0.1s ease-out;
  }

  &:focus,
  &:active {
    outline: none;
  }
}

ul.nav-menu li button {
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 999;
}

ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-color;
    padding: 10px 20px;
    font-size: 11px;
    text-transform: uppercase;

    &:first-child {
      margin-top: 10px;
    }
  }
}
.app-sidebar.minimized ul.nav-menu {
  .sub-menu {
    transition: none !important;
  }
}

.app-sidebar.minimized ul.nav-menu > li .sub-menu-btn-label .nav-text,
.app-sidebar.minimized ul.nav-menu > li > a > .nav-text {
  visibility: hidden;
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.app-sidebar.minimized ul.nav-menu > li:hover,
.app-sidebar.minimized ul.nav-menu > li:focus {
  .sub-menu {
    position: absolute;
    max-height: 1500px;
    left: 100%;
    top: 0;
  }
}

ul.nav-menu > li > button:hover,
ul.nav-menu > li > button:focus,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  background-color: $sidebar-bg-lighten-deep;
}

ul.nav-menu > li.open > button {
  background-color: $sidebar-bg-lighten-deep;
  .nav-text {
    color: $app-primary;
  }
}
ul.nav-menu > li.open > a {
  background-color: $sidebar-bg-lighten-deep;
  .nav-text {
    color: $app-primary;
  }
}

ul.nav-menu > li.open > a,
ul.nav-menu > li.open > button {
  box-shadow: inset -2px 0 0px #009ee3;
}

ul.nav-menu li.menu > button:focus:before,
ul.nav-menu li.menu > button:hover:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: $sidebar-hover-color;
}

ul.nav-menu li i {
  margin-right: 12px;
  font-size: 15px;
}

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: $sidebar-bg-lighten-deep;
  overflow: hidden;
}

ul.nav-menu li ul > li > a,
ul.nav-menu li ul > li > button {
  padding-left: 52px;
}

ul.nav-menu li ul > li > ul > li > a,
ul.nav-menu li ul > li > ul > li > button {
  padding-left: 72px;
}

ul.nav-menu li ul > li > ul > li > ul > li > a,
ul.nav-menu li ul > li > ul > li > ul > li > button {
  padding-left: 92px;
}

ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a,
ul.nav-menu li ul > li > ul > li > ul > li > ul > li > button {
  padding-left: 112px;
}

ul.nav-menu li.menu > button:before,
ul.nav-menu li.menu > a:before {
  color: $sidebar-text-color;
  content: '';
  display: inline-block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAA1+Nx+AAAAAXNSR0IArs4c6QAAAR9JREFUOBHNk71OAkEUhTeYkGBMCAlQ2KINlgRihx0doSPa2hlLAw8i8BqAD2ArVPYCBSVSWEk0kZ/vRma5Tpa4u27hTb7suTPnnt0Mg+N8V5LH9Vb/5XHGcMUOiLPwCGtoQQzC1AVDb/AORXBLQiXc0EMn3F1/4grbJ5iMGTplRk8QY7UppgFkwE81Ma3AhH+hb+xBCZNQY5LnCOTl++qAjQ7oGTme6r6BQzb61sCc/txjQLwPlveVvuTh/bEkP3Ab9Fct6GvKlUUPLc8LfU55fpUNHPpcl/S3cAoT0B/wRJ+GwHXJhL4ZEipnrMO79EFvHCO7KiPlbutQo+9ZD/uf2b0BlYcpmGA5ujuItI5Je4YPqEearMKO0AXV/w+5AWzPVVYjCT05AAAAAElFTkSuQmCC');
  background-size: contain;
  background-position: center;
  width: 0.8rem;
  height: 0.8rem;
  background-repeat: no-repeat;
  position: absolute;
  font: {
    family: 'Material-Design-Iconic-Font';
    size: 15px;
  }
  right: 1rem;
  position: absolute;
  @include rotate(-90deg);
  @include transition(all 200ms ease-in-out);
  // font-weight: normal;
}

ul.nav-menu li.menu.no-arrow > a:before {
  display: none !important;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: $sidebar-hover-color;
}

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before,
ul.nav-menu li.menu.open > button:before,
ul.nav-menu li.menu.active > button:before {
  @include rotate(0);
}

ul.nav-menu li.menu.open .sub-menu li > a {
  position: relative;
  background-color: transparent;

  // &:after {
  //   content: "";
  //   position: absolute;
  //   right: 15px;
  //   top: 50%;
  //   @include translateY(-50%);
  //   display: none;
  //   background-color: $white;
  //   width: 6px;
  //   height: 6px;
  //   @include border-radius($border-radius-circle);
  // }

  // &.active:after {
  //   display: inline-block;
  // }
}

ul.nav-menu li.menu .sub-menu li > a {
  @include border-right-radius($border-radius-xxl);
  margin-right: 20px;
  padding: 0.5rem 1rem 0.5rem 3rem !important;
  margin: 0 2rem 0 0;
  min-height: 2.25rem;
  transition: all 0.1s ease-out;
  .nav-text {
    font-size: 10pt;
    font-weight: 500;
    color: #616161;
    margin-left: 0 !important;
  }
}

ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: $sidebar-bg-darken-highlight;
  color: $sidebar-hover-color;
  .nav-text,
  i {
    color: #fff;
  }
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: transparent;
  // color: $sidebar-hover-color;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus {
  .nav-text {
    color: #000000;
  }

  i {
    color: #000000;
  }
}

ul.nav-menu .badge {
  line-height: 13px;
  margin-bottom: 0;
}

.headline-text,
.secondary-menu-wrapper .secondary-menu-title h2 {
  width: 100%;
  font-family: Roboto;
  font-size: 11pt;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #312e34;
  padding: 0;
  margin: 0 0.5rem;
}

.secondary-menu-enter {
  opacity: 0;
  width: 0;
  overflow: hidden;
  transform: scale(1) translate3d(-100%, 0, 0);
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);

  .secondary-menu-content {
    box-shadow: inset 200px 0 50px 0px #0000002d;
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transform: rotateY(-60deg);
    transform-origin: right;
  }

  @media screen and (max-width: 991px) {
    transform: scale(1) translate3d(0, -100%, 0);
  }
}
.secondary-menu-enter-active {
  opacity: 1;
  width: 100%;
  transform: scale(1) translate3d(0, 0, 0);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);

  .secondary-menu-content {
    box-shadow: inset 0 0 0 0 transparent;
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transform: rotateY(0deg);
    width: 100%;
  }
}
.secondary-menu-exit {
  opacity: 1;
  perspective: 1000px;
  overflow: hidden;
  transform-style: preserve-3d;
  transform: scale(1) translate3d(0, 0, 0);
  .secondary-menu-content {
    box-shadow: inset 0 0 0 0 transparent;
    transform-origin: right;
  }
}
.secondary-menu-exit-active {
  opacity: 0;
  width: 0;
  // perspective: 1000px;
  // overflow: hidden;
  // transform-style: preserve-3d;
  transform: scale(1) translate3d(-100%, 0, 0);
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);

  .secondary-menu-content {
    box-shadow: inset 200px 0 50px 0px #0000002d;
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transform: rotateY(-90deg);
    // transform-origin: right;
  }

  @media screen and (max-width: 991px) {
    transform: scale(1) translate3d(0, -100%, 0);
  }
}

.app-container {
  @media screen and (max-width: 991px) {
    height: 100%;
  }
}

.app-main {
  position: relative;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  @media screen and (max-width: 991px) {
    flex-direction: column;
    height: 100%;
  }
}

.app-container.secondary-menu {
  .app-main {
    padding-left: $secondary-menu-width;

    @media screen and (max-width: 991px) {
      padding-left: 0;
      // padding-top: calc(250px);
    }
  }
}

.secondary-menu-wrapper {
  position: absolute;
  z-index: 999;
  left: 0;
  bottom: 0;
  top: -$app-bar-height;
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  width: $secondary-menu-width;
  min-width: $secondary-menu-width;
  max-width: $secondary-menu-width;
  border-right: 1px solid $line-color-1;
  background-color: $app-primary;
  // border: 1px solid blue;
  // transform: translate3d(-100%, 0, 0);
  // opacity: 0;
  // visibility: hidden;
  // transition: all .2s ease-out;

  // &.active {
  //   transform: translate3d(0, 0, 0);
  //   opacity: 1;
  //   visibility: visible;
  // }

  @media screen and (max-width: 991px) {
    position: relative;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    // bottom: auto;
    // left: 0;
    // right: 0;
    top: 0;
    height: auto;
    height: 50vh;
    border-right: none;
    border-bottom: 1px solid $line-color-1;
  }

  .secondary-menu-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &.sm-type-1 {
      background-color: $sidebar-bg;
    }

    &.sm-type-2 {
      background-color: $accent-1;
    }
  }

  .secondary-menu-title {
    display: flex;
    width: 100%;
    padding: 1.5rem 1rem;
    height: $app-bar-height;
    border-bottom: 1px solid $line-color-1;
    justify-content: flex-start;
    align-items: center;

    h2 {
      margin: 0;
    }
  }

  .secondary-menu-data {
    display: flex;
    height: 100%;

    .nav-menu {
      li > a {
        padding: 0.85rem 1rem !important;
        min-height: 2rem;
        .nav-text {
          margin: 0 !important;
          font-size: 10pt;
          font-weight: 500;
        }
      }
    }

    .scrollbar {
      background-color: transparent;
      .nav-menu li {
        background-color: transparent !important;
      }
    }
  }
}

.top-menu-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: $accent-2;
  color: $headings-color;
  // overflow-x: auto;

  .top-menu-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem 2rem;
    // overflow-x: auto;

    .top-menu-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .top-menu-dismiss-btn {
        margin-right: 0.5rem;
        padding: 0.25rem;
      }

      h2 {
        font-size: 11pt;
        margin: 0;
        font-weight: 700;
        margin-right: 4rem;
      }
    }

    .top-menu-piece {
      margin: 0 1rem;
      font-size: 11pt;
      text-transform: capitalize;
      &:last-child {
        margin-left: auto !important;
      }
    }

    .top-menu-action {
      margin-left: auto;
    }
  }
}

.app-container.mini-drawer {
  & .side-nav {
    @include transition($transition-base);
    width: $mini-drawer-width;

    .navigation-icon {
      margin-left: 0.25rem;
    }

    & .nav-header span.nav-text,
    & .nav-menu > li > a span.nav-text,
    & .nav-menu > li > button span.nav-text,
    & .user-profile .user-detail,
    & .customizer {
      position: absolute;
      transition: all 0.2s ease-out;
      transform: translate3d(0, 0, 0);
      opacity: 0;
      visibility: hidden;
    }

    & .sub-menu {
      // position: absolute !important;
      transition: all 0.2s ease-out !important;
      transform: translate3d(0, -100%, 0);
      opacity: 0;
      visibility: hidden;
      min-width: $side-nav-width;
      max-height: 0 !important;
    }
    & .nav-menu li {
      text-align: center;
      i {
        margin-right: 0;
      }
    }

    & ul.nav-menu {
      & li a,
      & li button {
        padding: 7px 20px 8px 20px;
      }

      & button {
        text-align: center;
      }

      & ul.sub-menu li a {
        padding-left: 30px;
      }
    }

    & ul.nav-menu > li > a,
    & ul.nav-menu > li > button {
      &:before {
        display: none;
      }
    }

    &:not(:hover) {
      & .nav-header {
        margin-top: 10px;
        padding-top: 10px;
      }

      & ul.nav-menu li.menu + .nav-header {
        padding-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}

.app-container.mini-drawer {
  & .side-nav:hover {
    width: $side-nav-width;

    & .nav-menu li.menu {
      overflow: hidden;
    }

    & .nav-menu > li > a span.nav-text,
    & .nav-menu > li > button span.nav-text,
    & .user-profile .user-detail {
      display: inline-block !important;
      transform: translate3d(1rem, 0, 0);
      visibility: visible;
      opacity: 1;
    }

    & .nav-header span.nav-text,
    & .nav-menu .menu.open > .sub-menu {
      display: block !important;
      transform: translate3d(0, 0, 0);
      visibility: visible;
      opacity: 1;
      max-height: 1500px !important;
    }

    // & .sub-menu {

    // }

    // & .sub-menu {
    //   transform: translate3d(1rem, 0, 0);
    //   visibility: visible;
    //   opacity: 1;
    // }

    & .customizer {
      display: flex !important;
    }

    & ul.nav-menu {
      & li {
        text-align: left;
        & i {
          margin-right: 5px;
        }

        & a,
        & button {
          padding: 7px 20px 8px 20px;
        }

        & button {
          text-align: left;
        }
      }

      & ul.sub-menu li a {
        padding-left: 52px;

        // &:before {
        //   color: $sidebar-text-color;
        //   content: '';
        //   display: inline-block;
        //   background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAOCAYAAAA1+Nx+AAAAAXNSR0IArs4c6QAAAR9JREFUOBHNk71OAkEUhTeYkGBMCAlQ2KINlgRihx0doSPa2hlLAw8i8BqAD2ArVPYCBSVSWEk0kZ/vRma5Tpa4u27hTb7suTPnnt0Mg+N8V5LH9Vb/5XHGcMUOiLPwCGtoQQzC1AVDb/AORXBLQiXc0EMn3F1/4grbJ5iMGTplRk8QY7UppgFkwE81Ma3AhH+hb+xBCZNQY5LnCOTl++qAjQ7oGTme6r6BQzb61sCc/txjQLwPlveVvuTh/bEkP3Ab9Fct6GvKlUUPLc8LfU55fpUNHPpcl/S3cAoT0B/wRJ+GwHXJhL4ZEipnrMO79EFvHCO7KiPlbutQo+9ZD/uf2b0BlYcpmGA5ujuItI5Je4YPqEearMKO0AXV/w+5AWzPVVYjCT05AAAAAElFTkSuQmCC');
        //   background-size: contain;
        //   background-position: center;
        //   width: .8rem;
        //   height: .8rem;
        //   background-repeat: no-repeat;
        //   position: absolute;
        //   font: {
        //     family: 'Material-Design-Iconic-Font';
        //     size: 15px;
        //   }
        //   left: 1rem;
        //   position: absolute;
        //   @include rotate(-90deg);
        //   @include transition(all 200ms ease-in-out);
        //   // font-weight: normal;
        // }
      }

      ul.sub-menu > li > ul > li > a {
        padding-left: 72px;
      }

      ul.sub-menu > li > ul > li > ul > li > a {
        padding-left: 92px;
      }

      ul.sub-menu > li > ul > li > ul > li > ul > li > a {
        padding-left: 112px;
      }
    }

    & ul.nav-menu > li > a,
    & ul.nav-menu > li > button {
      &:before {
        display: block;
      }
    }
  }
}

.customizer {
  padding: 20px 35px;
  & img {
    padding: 5px;
    cursor: pointer;
  }
}

.user-info {
  & ul {
    & li {
      font-size: 15px;
    }
  }
}

.noIcon::before {
  display: none !important;
}
