/*App Layout Styles*/

#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%;
}

.bg-1 {
  background-image: $background-1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.p-25 {
  padding: 0.7rem !important;
}

.c-grey {
  color: $light-gray;
}

.app-container {
  @include display-flex(flex, row, nowrap);
  width: 100%;
}

.app-sidebar {
  @include display-flex(flex, column, nowrap);
  width: $side-nav-width;
  min-width: $side-nav-width;
  max-width: $side-nav-width;
}

.app-main-container {
  @include display-flex(flex, column, nowrap);
  width: 100%;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
  overflow: hidden;
}

.app-header {
  min-height: $app-bar-height;
  position: relative;
}

.app-main-content-wrapper {
  flex: 1;
  @include display-flex(flex, column, nowrap);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.app-container {
  &.fixed-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $side-nav-width;
      right: 0;

      @media screen and (max-width: 991px) {
        left: 0;
        right: 0;
      }
    }
  }

  &.collapsible-drawer {
    & .app-main-header {
      width: auto !important;
      @include transition(all 0.5s $transition-ease-out !important);
      left: 0;
      right: 0;
    }
  }

  &.mini-drawer {
    & .app-main-header {
      width: auto !important;
      height: $app-bar-height;
      @include transition(all 0.5s $transition-ease-out !important);
      left: $mini-drawer-width;
      right: 0;
      @media screen and (max-width: 1199px) {
        left: 0;
      }
      // @media screen and (max-width: 991px) {
      //   left: calc(#{$mini-drawer-width});
      // }
    }

    &.secondary-menu {
      .app-main-header {
        left: calc(#{$mini-drawer-width} + #{$secondary-menu-width});
        @media screen and (max-width: 1199px) {
          left: $secondary-menu-width;
        }
        @media screen and (max-width: 991px) {
          left: 0;
        }
      }
    }

    .app-sidebar {
      width: $mini-drawer-width;
      min-width: $mini-drawer-width;
      max-width: $mini-drawer-width;

      @media screen and (max-width: 1199px) {
        display: none !important;
      }
    }
  }
}

.app-header-horizontal {
  & .app-main-header {
    left: auto !important;
    position: relative !important;
    right: auto !important;

    & + .app-top-nav {
      @include box-shadow(
        0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12)
      );
    }
  }

  & .app-top-nav {
    padding: 0 24px;
    position: relative;
    z-index: 1101;

    & .app-toolbar {
      min-height: $app-bar-height - 30px;
      height: $app-bar-height - 30px;
      max-height: $app-bar-height - 30px;
    }
  }

  & .app-main-header-top {
    z-index: 1102;
  }
}

.navigation-logo-wrapper {
  height: $app-bar-height;
  background-color: #ffffff;
  border-bottom: solid 1px #dfddee !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0.5rem;
}
.navigation-logo {
  width: calc(#{$mini-drawer-width} - 1.8rem);
  height: auto;
  margin: 0 auto;
}
.navigation-left-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // min-width: 700px;
}
.icon-btn {
  background: gray !important;
}
.ml-auto {
  margin-left: 0px !important;
}

.navigation-notification {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-left: solid 1px $line-color-1 !important;
  border-right: solid 1px $line-color-1 !important;
  height: $app-bar-height !important;
  width: 62px !important;
  margin: 0 0.75rem !important;
  border-radius: 0 !important;

  @media screen and (max-width: 991px) {
    width: 50px !important;
    min-width: 50px !important;
  }
}
ul.nav-menu li a {
  padding: 13px;
}

button {
  text-transform: capitalize !important;
}

.bb-1 {
  border-bottom: 1px solid $line-color-1;
}

.notification-img {
  width: 16px;
  height: 20px;
}

.jr-menu-icon .menu-icon {
  background: blue !important;
}

.arrowImg {
  width: 12px;
  height: 7px;
  margin-left: 132px;
  margin-top: 4px;
}
.hide {
  display: none;
}
.visible {
  display: flex;
  height: 153px;
  width: 262px;
  flex-direction: column;
  padding-left: 65px;
}
.visible > div {
  margin-top: 20px;
}

.MuiDrawer-paperAnchorDockedLeft-136 {
  border-right: solid 1px #dfddee !important;
}

@media only screen and (max-width: 900px) {
  .headline-text {
    display: none;
  }
}

.app-delete-modal_header {
  background-color: #37dfb6 !important;
  color: black;
  padding: 10px;
  width: 100% !important;
  left: 0;
  height: 55px;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 53px;
  right: 0;
  overflow: hidden;
  border-bottom: solid 1px #dfddee;
  box-shadow: none !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .delete-modal_items-list {
    display: flex;
    align-items: center;
    padding-left: 10px;
    h3 {
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #312e34;
      margin-bottom: 0px;
    }
    span {
      width: 20px;
      height: 21px;
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      margin-right: 10px;
      justify-content: center;
      cursor: pointer;
    }
  }
  .delete-modal_delete,
  .app-delete-modal_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 15px;
    height: 40px;
    border-radius: 20px;

    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      font-size: 13px;
      font-family: 'gilroyMedium';
      svg {
        margin-right: 7px;
      }
    }
    .import-button {
      color: #006fff;
    }
  }
}

.app-delete-modal_right {
  display: flex;
}

.progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users-loader {
  padding-bottom: 100px;
  padding-top: 100px;
}

@media screen and (max-width: 1199px) {
  .navigation-icon {
    margin-right: 15px;
  }
}
