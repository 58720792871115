.close-x__button--wrapper {
    width: 21px;
    height: 21px;
    background: #d8d8d8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;
}   

.app-forget-password .close-x__button--link {
    margin-top: 0;
    display: inline-block;
    width: 100%;
    border-radius: 50%;
    padding-left: 7px;
}

.app-forget-password__sent .close-x__button--link {
    margin-top: -2px;
}

.close-x__button {
    width: 7px;
    height: 7px;
}