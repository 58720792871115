.MuiPaper-elevation8 {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1) !important;
}
.MuiList-padding {
  padding: 5px 10px !important;
}

.category-loader {
  padding-top: 100px;
}

.categories-table {
  margin: 10px;

  .levelOne {
    margin-right: 15px;
    margin-bottom: 20px;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categories-table__levels {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    .categories-table-levels__level {
      background-color: #ffffff;
      width: 222px;
      border-radius: 4px;
      border: solid 1px #dedcec;
      height: 100%;
      display: block;
      //margin-right: 30px;

      .level-header {
        width: 100%;
        height: 50px;
        background-color: #ececf4;
        border-radius: 3px 3px 0 0;
        h3 {
          margin: 0;
          font-family: 'Roboto';
          font-size: 15px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.8;
          letter-spacing: normal;
          color: #272528;
        }
      }
      .level-addnew {
        width: 100%;
        height: 50px;
        border-bottom: solid 1px #dedcec;
        .level-addnew_flex {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          svg {
            cursor: pointer;
          }
          .level-addnew_input {
            font-family: 'Roboto';
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 3.5;
            letter-spacing: normal;
            margin: 0;
            border: none;
            width: 90%;
            &:active {
              outline: none;
            }
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: #545475;
            }
          }
          &:hover {
            text-decoration: none;
          }
        }
      }

      .category-list {
        margin: 0;
        padding: 0;
        width: 100%;
        .category-list__item {
          width: 100%;
          height: 44px;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          cursor: pointer;
          position: relative;
          &:hover {
            background-color: #009ee3;
            color: #fff;
          }
          &:active {
            background-color: #009ee3;
            color: #fff;
          }
          &:last-child {
            border-radius: 0px 0px 3px 3px;
          }
          .list-item__toggle,
          .list-item__dots {
            display: none;
          }
          .list-item__name {
            width: 75%;
            margin: auto;
          }
        }
        .active {
          background-color: #009ee3;
          color: #fff;
          position: relative;
          .list-item__toggle,
          .list-item__dots {
            display: flex;
            align-items: center;
          }
          .list-item__name {
            width: 75%;
          }
        }
      }
    }
  }
}

.category-edit_icon {
  &:hover {
    text-decoration: none !important;
  }
}

.category_ismain {
  display: flex;
  .switch {
    position: relative;
    display: inline-block;
    width: 53px !important;
    height: 34px;
    margin-top: 10px;
  }
  .switch_label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    align-items: center;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    width: 53px !important;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 1px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #6eb24b;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
